import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import VideoPlayer from "../../components/VideoPlayer";
import Footer from "../../components/Footer/footer";
import Quiz from "../../components/Quiz/Quiz";
import Checklist from "../../components/Checklist/Checklist";
import HomeIcon from "../../components/HomeIcon/HomeIcon";
import Certificate from "../../components/Certificate/Certificate";
import "./lancamento.css";

const videoSections = {
  sections: [
    {
      title: "Orientações Pré-Lançamento",
      videos: [
        {
          id: 1,
          title: "1 - Lançamento, Autenticador de 2 Fatores(2FA) e Pesquisa de Satisfação",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Lancamento/Elementos%20pré%20lançamento%20do%20site.mp4",
          showQuiz: false,
          showChecklist: false,
        },
      ],
    },
    {
      title: "Orientações Pós-lançamento",
      videos: [
        {
          id: 2,
          title: "1 - Demais Tarefas e Elementos Importantes",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Lancamento/Elementos%20pós%20lançamento%20pt.01.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 3,
          title: "2 - Considerações Finais e Elementos de Lançamento",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Lancamento/Elementos%20pós%20lançamento%20pt.02.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
            id : 4,
            title: 'Conclusão do Módulo',
            certificate: true,
          }
      ],
    },
  ],
};

function Lancamento() {
  const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
  const [currentVideoId, setCurrentVideoId] = useState(1);
  const [activeSection, setActiveSection] = useState(0);
  const [quizActive, setQuizActive] = useState(false);
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [quizReasons, setQuizReasons] = useState([]);
  const [checklistActive, setChecklistActive] = useState(false);
  const [checklistItems, setChecklistItems] = useState([]);
  const [checklistTitle, setChecklistTitle] = useState("");
  const [certificateActive, setCertificateActive] = useState(false);

  const handleVideoSelect = (
    videoUrl,
    videoId,
    showQuiz,
    showChecklist,
    checklist,
    checklistTitle,
    certificate
  ) => {
    if (currentVideoId === videoId) {
      return;
    }

    setSelectedVideoUrl(videoUrl);
    setCurrentVideoId(videoId);

    if (showQuiz) {
      if (!quizActive) {
        setQuizActive(true);
        setChecklistActive(false);
        setCertificateActive(false);
        const selectedVideo = videoSections.sections
          .flatMap((section) => section.videos)
          .find((video) => video.id === videoId);

        if (selectedVideo && selectedVideo.quiz) {
          setQuizQuestions(selectedVideo.quiz.questions);
          setQuizReasons(
            selectedVideo.quiz.questions.map((question) => question.reasons)
          );
        } else {
          setQuizQuestions([]);
          setQuizReasons([]);
        }
      }
    } else if (showChecklist) {
      if (!checklistActive) {
        setChecklistActive(true);
        setQuizActive(false);
        setCertificateActive(false);
        setChecklistItems(checklist.items);
        setChecklistTitle(checklistTitle);
      }
    } else if (certificate) {
      setCertificateActive(true);
      setQuizActive(false);
      setChecklistActive(false);
    } else {
      setQuizActive(false);
      setChecklistActive(false);
      setCertificateActive(false);
      setQuizQuestions([]);
      setQuizReasons([]);
      setChecklistItems([]);
      setChecklistTitle("");
    }
  };

  const handleNextVideo = () => {
    const currentSectionIndex = videoSections.sections.findIndex((section) =>
      section.videos.some((video) => video.id === currentVideoId)
    );
    const currentSection = videoSections.sections[currentSectionIndex];
    const currentVideoIndex = currentSection.videos.findIndex(
      (video) => video.id === currentVideoId
    );

    if (currentVideoIndex < currentSection.videos.length - 1) {
      const nextVideo = currentSection.videos[currentVideoIndex + 1];
      handleVideoSelect(
        nextVideo.url,
        nextVideo.id,
        nextVideo.showQuiz,
        nextVideo.showChecklist,
        nextVideo.checklist,
        nextVideo.checklistTitle,
        nextVideo.certificate
      );
    } else if (currentSectionIndex < videoSections.sections.length - 1) {
      const nextSection = videoSections.sections[currentSectionIndex + 1];
      const firstVideoOfNextSection = nextSection.videos[0];
      handleVideoSelect(
        firstVideoOfNextSection.url,
        firstVideoOfNextSection.id,
        firstVideoOfNextSection.showQuiz,
        firstVideoOfNextSection.showChecklist,
        firstVideoOfNextSection.checklist,
        firstVideoOfNextSection.checklistTitle,
        firstVideoOfNextSection.certificate
      );
      setActiveSection(currentSectionIndex + 1);
    }
  };

  const handlePreviousVideo = () => {
    const currentSectionIndex = videoSections.sections.findIndex((section) =>
      section.videos.some((video) => video.id === currentVideoId)
    );
    const currentSection = videoSections.sections[currentSectionIndex];
    const currentVideoIndex = currentSection.videos.findIndex(
      (video) => video.id === currentVideoId
    );

    if (currentVideoIndex > 0) {
      const previousVideo = currentSection.videos[currentVideoIndex - 1];
      handleVideoSelect(
        previousVideo.url,
        previousVideo.id,
        previousVideo.showQuiz,
        previousVideo.showChecklist,
        previousVideo.checklist,
        previousVideo.checklistTitle,
        previousVideo.certificate
      );
    } else if (currentSectionIndex > 0) {
      const previousSection = videoSections.sections[currentSectionIndex - 1];
      const lastVideoOfPreviousSection =
        previousSection.videos[previousSection.videos.length - 1];
      handleVideoSelect(
        lastVideoOfPreviousSection.url,
        lastVideoOfPreviousSection.id,
        lastVideoOfPreviousSection.showQuiz,
        lastVideoOfPreviousSection.showChecklist,
        lastVideoOfPreviousSection.checklist,
        lastVideoOfPreviousSection.checklistTitle,
        lastVideoOfPreviousSection.certificate
      );
      setActiveSection(currentSectionIndex - 1);
    }
  };

  const handleQuizComplete = () => {
    setQuizActive(false);
    handleNextVideo();
  };

  const handleChecklistComplete = () => {
    setChecklistActive(false);
    handleNextVideo();
  };

  useEffect(() => {
    const currentVideo = videoSections.sections
      .flatMap((section) => section.videos)
      .find((video) => video.id === currentVideoId);
    if (currentVideo) {
      setSelectedVideoUrl(currentVideo.url);
      setQuizActive(currentVideo.showQuiz);
      setChecklistActive(currentVideo.showChecklist);
      setCertificateActive(currentVideo.certificate === true);
      if (currentVideo.checklist) {
        setChecklistItems(currentVideo.checklist.items);
        setChecklistTitle(currentVideo.checklistTitle);
      } else {
        setChecklistItems([]);
        setChecklistTitle("");
      }
      setQuizQuestions(currentVideo.quiz ? currentVideo.quiz.questions : []);
      setQuizReasons(
        currentVideo.quiz
          ? currentVideo.quiz.questions.map((question) => question.reasons)
          : []
      );
    }
  }, [currentVideoId]);

  return (
    <div className="container-cp">
      <HomeIcon />
      <Sidebar
        sections={videoSections.sections}
        onVideoSelect={handleVideoSelect}
        currentVideoId={currentVideoId}
        activeSection={activeSection}
        setActiveSection={setActiveSection}
      />

      {quizActive ? (
        <Quiz
          questions={quizQuestions}
          reasons={quizReasons}
          onQuizComplete={handleQuizComplete}
        />
      ) : checklistActive ? (
        <Checklist
          title={checklistTitle}
          items={checklistItems}
          onChecklistComplete={handleChecklistComplete}
        />
      ) : certificateActive ? (
        <Certificate moduleName="Lançamento" />
      ) : (
        <VideoPlayer
          videoUrl={selectedVideoUrl}
          onNextClick={handleNextVideo}
          onBackClick={handlePreviousVideo}
        />
      )}

    </div>
  );
}

export default Lancamento;

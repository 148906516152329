import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { registerAccess, registerExit } from '../../services/api';
import { loginAndRegisterAccess } from '../../services/api';
import { jwtDecode } from 'jwt-decode';
import LoginImage from '../../assets/loginImage1.svg';
import FooterSVG from "../../assets/logoMagazord.svg";
import './Login.css';

Modal.setAppElement('#root');

const Login = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [userEmail, setUserEmail] = useState(null);

  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();

  const openModal = (message) => {
    setModalMessage(message);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const onSubmit = async (data) => {
    try {
      const response = await loginAndRegisterAccess(data.email, data.password);
      const { token, isAdmin, email } = response;

      if (!token) {
        throw new Error('Token não recebido');
      }

      const decodedToken = jwtDecode(token);

      if (decodedToken.email !== email) {
        throw new Error('Dados do token inválidos');
      }

      localStorage.setItem('token', token);
      localStorage.setItem('is_admin', isAdmin ? 'true' : 'false');
      localStorage.setItem('email', email);

      await registerAccess(email);

      openModal('Login bem-sucedido! Você será redirecionado para a página inicial.');
      setTimeout(() => {
        navigate('/');
      }, 2000);

      setUserEmail(email);
    } catch (error) {
      console.error('Erro de login:', error.message);
      openModal('Email ou senha inválidos');
    }
  };

  useEffect(() => {
    const handleBeforeUnload = async () => {
      if (userEmail) {
        await registerExit(userEmail);
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [userEmail]);

  useEffect(() => {
    if (errors.email || errors.password) {
      openModal('Preencha todos os campos obrigatórios.');
    }
  }, [errors.email, errors.password]);

  return (
    <div className="login-container">

      <div className='card-login'>
        <div className="login">
          <div className='forms'>
            <h1>Login</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                {...register('email')}
              />
              {errors.email && <p className="error">{errors.email.message}</p>}

              <label htmlFor="password">Senha:</label>
              <input
                type="password"
                id="password"
                {...register('password')}
              />
              {errors.password && <p className="error">{errors.password.message}</p>}

              <p className="no-user" >
                Não possui um usuário?{' '}
                <span style={{ color: '#b35042', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => openModal('Contate seu analista de sucesso, suporte ou de Implantação!')}>
                  Clique aqui
                </span>
              </p>

              <div className='contLogin-btn'>
                <button type="submit">Login</button>
              </div>
            </form>


          </div>
        </div>

        <div className='div-imageLogin'>
          <img className='img-login' src={LoginImage} alt="Imagem na tela de Login" />
          <img className='img-MagaLogin' src={FooterSVG} alt="Imagem na tela de Login" />
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Status do Login"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2>{modalMessage}</h2>
        <div style={{display: 'flex', justifyContent : 'center'}}> 
          <button className='close-button'  onClick={closeModal}>Fechar</button>
        </div>
      </Modal>
    </div>
  );
};

export default Login;

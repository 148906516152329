import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Card from "../../components/Card/Card";
import { BsGear } from "react-icons/bs";
import { checkAdminStatus } from "../../services/api";
import FooterSVG from "../../assets/logoMagazord.svg"
import "./Home.css";

function Home() {
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);

  const modulos = [
    {
      id: 1,
      Tempo: "86 Minutos",
      nome: "Onboarding",
      Quantidade: "12 Aulas",
      questoes: 3,
      rota: "/onboarding",
      media: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Suporte/Camila/Alison/onboarding.png",
      moduleName: "onboarding"
    },
    {
      id: 2,
      Tempo: "60 Minutos",
      nome: "Cadastro de Produtos",
      Quantidade: "15 à 20 Aulas",
      questoes: 3,
      rota: "/select",
      media: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Suporte/Camila/Alison/cadastro.png",
      moduleName: "cadastro"
    },
    {
      id: 3,
      Tempo: "60 Minutos",
      nome: "Front-End",
      Quantidade: "15 Aulas",
      questoes: 3,
      rota: "/front",
      media: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Suporte/Camila/Alison/front-end.png",
      moduleName: "front"
    },
    {
      id: 4,
      Tempo: "26 Minutos",
      nome: "Faturamento",
      Quantidade: "10 Aulas",
      questoes: "Nenhuma Questão",
      rota: "/faturamento",
      media: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Suporte/Camila/Alison/Faturamento.png",
      moduleName: "faturamento"
    },
    {
      id: 5,
      Tempo: "16 Minutos",
      nome: "Lançamento",
      Quantidade: "3 Aulas",
      questoes: 0,
      rota: "/lancamento",
      media: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/lancamento.png",
      moduleName: "lancamento"
    },
  ];

  const checkModuleCompletion = (moduleName) => {
    const totalVideos = modulos.find((modulo) => modulo.moduleName === moduleName).Quantidade.split(" ")[0];
    const lastVideoId = localStorage.getItem(`${moduleName}-lastVideoId`);
    return lastVideoId && parseInt(lastVideoId, 10) >= totalVideos;
  };

  const modulosCompletos = modulos.map((modulo) => ({
    ...modulo,
    completed: checkModuleCompletion(modulo.moduleName),
  }));

  const handleAccessModule = (rota) => {
    navigate(rota);
  };

  const handleGoToAdmin = () => {
    navigate("/admin");
  };

  useEffect(() => {
    const checkAdmin = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const response = await checkAdminStatus(token);
          setIsAdmin(response.isAdmin);
        } catch (error) {
          console.error("Erro ao verificar status de admin:", error);
        }
      }
    };

    checkAdmin();
  }, []);

  return (
    <div className="home">
      
      <div className="container-body">
        {isAdmin && (
          <div className="admin-button-container" onClick={handleGoToAdmin}>
            <BsGear icon={BsGear} size="40px" className="admin-icon" />
          </div>
        )}



        <h1>Ambiente Virtual de Aprendizado - Magazord</h1>
        <div className="modulos">
          {modulosCompletos.map((modulo) => (
            <Card key={modulo.id} modulo={modulo} onClick={() => handleAccessModule(modulo.rota)} />
          ))}
        </div>
        <footer className="footer-home">
          <img src={FooterSVG} alt="Magazord" />
        </footer>
      </div>
    </div>
  );
}


export default Home;
import React, { useState } from 'react';
import { IoSearchOutline } from "react-icons/io5"; // Importando o ícone de busca
import { useNavigate } from 'react-router-dom'; // Importando useNavigate
import './header.css';

const Header = ({ onSearch }) => { // Aceitando uma função de callback como prop
    const navigate = useNavigate(); // Usando o hook useNavigate
    const [searchTerm, setSearchTerm] = useState(""); // Estado para armazenar o valor da busca

    const handleSearchChange = (e) => {
        const term = e.target.value;
        setSearchTerm(term);
        if (typeof onSearch === 'function') { // Verifica se onSearch é uma função
            onSearch(term); // Chama a função de busca com o termo atual
        }
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault(); // Impede o comportamento padrão do formulário
        // Aqui você pode adicionar lógica para realizar a pesquisa se necessário
        if (typeof onSearch === 'function') {
            onSearch(searchTerm);
        }
    };

    return (
        <header className="header-container">
            <div className="logo-container" onClick={() => navigate(`/`)} style={{ cursor: 'pointer' }}>
                <img
                    src="https://s3.amazonaws.com/cdn.freshdesk.com/data/helpdesk/attachments/production/151014332039/logo/6GF-CygnCflbgNwyzOyfW88_QVY_Fds6vg.png"
                    alt="Logotipo"
                    className="logo"
                />
                <span className="brand-name">AVA - Magazord</span>
            </div>

            <nav className="navbar">
                <ul className="nav-list">
                    <li className="nav-item">
                        <a onClick={() => navigate(`/`)} className="nav-link" style={{ cursor: 'pointer' }}>Início</a>
                    </li>
                    <li className="nav-item">
                        <a onClick={() => navigate(`/select`)} className="nav-link" style={{ cursor: 'pointer' }}>Trilha de Conhecimento</a>
                    </li>
                    <li className="nav-item search-container">
                        <form className="search-form" onSubmit={handleSearchSubmit}>
                            <input
                                type="text"
                                placeholder="Buscar..."
                                value={searchTerm}
                                onChange={handleSearchChange} // Atualiza o estado da busca
                                className="search-input"
                            />
                            <button type="submit" className="search-button" style={{ display: 'none' }} /> {/* Opcional: botão de submissão oculto */}
                        </form>
                        <IoSearchOutline className="search-icon" onClick={handleSearchSubmit} style={{ cursor: 'pointer' }} />
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SelectScreen from "./pages/SelectScreen/index";
import Home from "./pages/Home/Home";
import Construcao from "./pages/Construcao/Construcao";
import Joias from "./pages/Joias/Joias";
import Estrategico from "./pages/Estrategico/Estrategico";
import Moda from "./pages/Moda/Moda";
import Moveis from "./pages/Moveis/Moveis";
import Onboarding from "./pages/Onboarding/Onboarding";
import Faturamento from "./pages/Faturamento/Faturamento";
import Lancamento from "./pages/Lancamento/lancamento";
import Front from "./pages/Front/Front";
import Login from "./pages/Login/Login";
import AdminDashboard from "./pages/AdminDashboard/AdminDashboard";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import useTrackExit from '../src/hooks/useTrackExit';
import FAQDetailsPage from "./pages/FaqDetails/FaqDetailsPage";
import FAQListPage from '../src/pages/Faq/FAQListPage';


function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  useEffect(() => {
    const checkWindowSize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    checkWindowSize();

    window.addEventListener("resize", checkWindowSize);

    return () => {
      window.removeEventListener("resize", checkWindowSize);
    };
  }, []);

  useTrackExit();

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem('token');
      localStorage.removeItem('is_admin');
      sessionStorage.clear();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <Router>
      <div>
        {isMobile && (
          <div id="info-div">
            <div className="container-infodiv">
              <p>
                Este conteúdo não está disponível em dispositivos com largura de
                tela inferior a 1200px.
              </p>
            </div>
          </div>
        )}
        <div className={isMobile ? "inaccessible" : ""}>
          <Routes>
            <Route path="/" element={<ProtectedRoute element={Home} adminOnly={false} />} />
            <Route path="/select" element={<ProtectedRoute element={SelectScreen} adminOnly={false} />} />
            <Route path="/construcao" element={<ProtectedRoute element={Construcao} adminOnly={false} />} />
            <Route path="/joias" element={<ProtectedRoute element={Joias} adminOnly={false} />} />
            <Route path="/estrategico" element={<ProtectedRoute element={Estrategico} adminOnly={false} />} />
            <Route path="/moda" element={<ProtectedRoute element={Moda} adminOnly={false} />} />
            <Route path="/moveis" element={<ProtectedRoute element={Moveis} adminOnly={false} />} />
            <Route path="/onboarding" element={<ProtectedRoute element={Onboarding} adminOnly={false} />} />
            <Route path="/front" element={<ProtectedRoute element={Front} adminOnly={false} />} />
            <Route path="/faturamento" element={<ProtectedRoute element={Faturamento} adminOnly={false} />} />
            <Route path="/lancamento" element={<ProtectedRoute element={Lancamento} adminOnly={false} />} />
            <Route path="/faqs" element={<ProtectedRoute element={FAQListPage} adminOnly={false} />} />
            <Route path="/login" element={<Login />} />
            <Route path="/admin" element={<ProtectedRoute element={AdminDashboard} adminOnly={true} />} />
            <Route path="/faq" element={<FAQListPage />} />
            <Route path="/faq/:moduleName" element={<FAQDetailsPage />} />
            <Route path="/faq/:moduleName/:id" element={<FAQDetailsPage />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;

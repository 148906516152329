import React from 'react';
import CardHeader from './CardHeader';
import AccessButton from './AccessButton';
import './Card.css';
import clockIcon from '../../assets/clockIcon.svg';
import bookIcon from '../../assets/bookIcon.svg';
import pencilIcon from '../../assets/pencilIcon.svg';




function Card({ modulo, onClick }) {
  return (
    <div className="modulo-card" onClick={onClick} style={{'cursor' :'pointer' }}>
      <CardHeader nome={modulo.nome} media={modulo.media} />
      <div className="modulo-info">
        <p>
          <img src={clockIcon} alt="Clock Icon" className="icon" /> 
          <strong>Tempo Estimado:</strong>&nbsp; {modulo.Tempo}
        </p>
        <p>
          <img src={bookIcon} alt="Video Icon" className="icon" /> 
          <strong>Vídeos :</strong> &nbsp; {modulo.Quantidade}
        </p>
        {modulo.questoes > 0 ? (
        <p>
          <img src={pencilIcon} alt="Question Icon" className="icon" /> 
          <strong>Questões:</strong>&nbsp; {modulo.questoes}
        </p>
      ) : (
        <p src={pencilIcon} className="icon">
          <img src={pencilIcon} alt="Question Icon" className="icon" />
          <strong>Questões : </strong>&nbsp;Não Possui 
        </p>
      )}
      </div>
      <AccessButton rota={modulo.rota} onClick={onClick} />
    </div>
  );
}

export default Card;

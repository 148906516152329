import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SelectScreen.css';
import FooterSVG from '../../assets/logoMagazord.svg'
import HomeIcon from '../../components/HomeIcon/HomeIcon';

const SelectScreen = () => {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState('');

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    switch (event.target.value) {
      case 'construção':
        navigate('/construcao');
        break;
      case 'moda':
        navigate('/moda');
        break;
      case 'moveis':
        navigate('/moveis');
        break;
      case 'joias':
        navigate('/joias');
        break;
      case 'estrategico':
        navigate('/estrategico');
        break;
      default:
        break;
    }
  };

  return (
    <div className='container-select'>
       <HomeIcon /> {}
      <div className='bodySelect'>

        <h2>Qual seu Nicho de Atuação :</h2>
        <div className='select-div'>
          <select className='select-box' value={selectedCategory} onChange={handleCategoryChange}>
            <option value="">Selecione seu Nicho ...</option>
            <option value="construção">Casa e Construção</option>
            <option value="moda">Moda</option>
            <option value="moveis">Móveis</option>
            <option value="joias">Jóias</option>
            <option value="estrategico">Migração</option>
          </select>

        </div>
        
      
      <footer className="footer-selectScreen">
       <img src={FooterSVG} alt="Magazord" />
      </footer>
      </div>
    </div>

  );
};

export default SelectScreen;

// FAQDetailsPage.js
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { listFaq, getFaqById } from '../../services/api';
import FAQList from '../../components/FAQList/FAQList';
import FAQDetails from '../../components/FaqDetails/FaqDetails'; // Importando o novo componente
import './FaqDetailsPage.css';
import Header from '../../components/Header/header';

const FAQDetailsPage = () => {
  const { moduleName, id } = useParams(); 
  const navigate = useNavigate();
  const [faqs, setFaqs] = useState([]);
  const [filteredFaqs, setFilteredFaqs] = useState([]);
  const [faqDetails, setFaqDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = sessionStorage.getItem('token');

  const moduleMapping = {
    onboarding: 1,
    'cadastro de produto': 2,
    front: 3,
    marketing: 4,
    pagamentos: 5,
    transporte: 6,
    faturamento: 7,
  };

  const moduleIcons = {
    onboarding: 'https://s3.amazonaws.com/cdn.freshdesk.com/data/helpdesk/attachments/production/151033266431/original/T6VnLSDLumbm0fTAhc6_JCzqb015SWJ1DA.png',
    'cadastro de produto': 'https://s3.amazonaws.com/cdn.freshdesk.com/data/helpdesk/attachments/production/151033258899/original/75OSEas5C1U6zxb8KW8y0XSb2X42_Y7Zgg.png',
    front: 'https://s3.amazonaws.com/cdn.freshdesk.com/data/helpdesk/attachments/production/151033259300/original/L8GALxpbC-QGj1za_QE-gtbCUw53Lbs3Fg.png',
    marketing: 'https://s3.amazonaws.com/cdn.freshdesk.com/data/helpdesk/attachments/production/151033261516/original/SwR4GflQoiIIo6XBSdwZ1mb_PNM7d68_fQ.png',
    pagamentos: 'https://s3.amazonaws.com/cdn.freshdesk.com/data/helpdesk/attachments/production/151033262332/original/HcckvjxGWHI_ZLyBNsueB43lCFfTaIz-ug.png',
    transporte: 'https://s3.amazonaws.com/cdn.freshdesk.com/data/helpdesk/attachments/production/151033260221/original/xj9dwzuy5J3q-ejQPknQ8LQAfTDQr1OeWg.png',
    faturamento: 'https://s3.amazonaws.com/cdn.freshdesk.com/data/helpdesk/attachments/production/151033259409/original/AX2grsBStvTDpxfCvi7V-N7rv1zFdA_9Mw.png'
  };

  const fetchFAQs = async () => {
    if (!token) {
      setError('Token não encontrado. Redirecionando para a página de login...');
      setTimeout(() => navigate('/login'), 3000);
      return;
    }

    try {
      const data = await listFaq(token);
      setFaqs(data);
      setFilteredFaqs(data); // Inicialmente, exiba todas as FAQs
    } catch (err) {
      console.error('Erro ao buscar FAQs:', err);
      setError(`Erro ao buscar FAQs: ${err.message}`);
    }
  };

  const fetchFaqDetails = async (faqId) => {
    if (!token) {
      setError('Token não encontrado. Redirecionando para a página de login...');
      setTimeout(() => navigate('/login'), 3000);
      return;
    }

    try {
      const details = await getFaqById(faqId, token);
      setFaqDetails(details);
    } catch (err) {
      console.error('Erro ao buscar FAQ:', err);
      setError(`Erro ao buscar FAQ: ${err.message}`);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        await fetchFaqDetails(id);
      } else {
        await fetchFAQs();
      }
      setLoading(false);
    };

    fetchData();
  }, [id, token, navigate]);

  // Função para filtrar FAQs com base na consulta de busca
  const handleSearch = (searchTerm) => {
    const filtered = faqs.filter(faq => 
      faq.question.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredFaqs(filtered);
  };

  if (loading) return <p>Carregando FAQs...</p>;
  if (error) return <p>{error}</p>;

  const moduleIconUrl = moduleIcons[moduleName.toLowerCase()];  
  const capitalizeFirstLetterOfEachWord = (string) => {
    return string.split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <>
      <Header onSearch={handleSearch} /> {/* Passar handleSearch para o Header */}
      <div className='container-faqDetails'> 
        <div className='faqDetails'>
          <div className="module-header">
            {moduleIconUrl ? (
              <img 
                src={moduleIconUrl} 
                alt={`Ícone ${moduleName}`} 
                className="module-icon" 
              />
            ) : (
              <p>Ícone não encontrado.</p>
            )}
            <h1>Perguntas Frequentes - {capitalizeFirstLetterOfEachWord(moduleName)}</h1>
          </div>
          {id ? (
            <FAQDetails faqDetails={faqDetails} /> // Usando o novo componente
          ) : (
            <div className='faq-list'>
              <FAQList moduleId={moduleMapping[moduleName.toLowerCase()]} faqs={filteredFaqs} /> 
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FAQDetailsPage;

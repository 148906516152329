import React, { useState } from 'react';
import UserForm from '../../components/AdminDashboard/UserForm';
import UserList from '../../components/AdminDashboard/UserList';
import AcessList from '../../components/AdminDashboard/AcessList';
import { useNavigate } from 'react-router-dom';

// import FAQForm from '../../components/FAQForm/FAQForm';
// import FAQDelete from '../../components/FAQDelete/FaqDelete'; 
import './AdminDashboard.css';

const AdminDashboard = () => {
  const navigate = useNavigate(); 
  const [activeTab, setActiveTab] = useState('users');
  const [selectedFaqId, setSelectedFaqId] = useState(null);

  return (
    <div className="admin-dashboard">
      <div className='header-admin'>
        <div className="logo-container" onClick={() => navigate(`/`)} style={{ cursor: 'pointer' }}>
          <img
            src="https://s3.amazonaws.com/cdn.freshdesk.com/data/helpdesk/attachments/production/151014332039/logo/6GF-CygnCflbgNwyzOyfW88_QVY_Fds6vg.png"
            alt="Logotipo"
            className="logo"
          />
          <span className="brand-name">AVA - Magazord</span>
        </div>
        <nav>
          <button onClick={() => setActiveTab('listUsers')}>Listar Usuários</button>
          <button onClick={() => setActiveTab('listAccess')}>Listar Acessos</button>
          
          <button onClick={() => setActiveTab('users')}>Cadastrar Usuário</button>
          {/* <button onClick={() => setActiveTab('faqform')}>Criar FAQ</button> */}
          {/* <button onClick={() => setActiveTab('deleteFaq')}>Deletar FAQ</button> */}
        </nav>
      </div>

      <div className="content">
        {activeTab === 'users' && <UserForm />}
        {activeTab === 'listUsers' && <UserList />}
        {activeTab === 'listAccess' && <AcessList />}
        {/* {activeTab === 'faqform' && <FAQForm />} */}
        {/* {activeTab === 'deleteFaq' && <FAQDelete id={selectedFaqId} setSelectedFaqId={setSelectedFaqId} />} */}
      </div>
    </div>
  );
};

export default AdminDashboard;

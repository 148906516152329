import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import VideoPlayer from "../../components/VideoPlayer";
import Footer from "../../components/Footer/footer";
import Quiz from "../../components/Quiz/Quiz";
import Checklist from "../../components/Checklist/Checklist";
import HomeIcon from "../../components/HomeIcon/HomeIcon";
import Certificate from "../../components/Certificate/Certificate";
import "./Estrategico.css";


const videoSections = {
  sections: [
    {
      title: "Pré Cadastro",
      videos: [
        {
          id: 1,
          title: "1 - Entendendo Sobre Categorias",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Estrat%C3%A9gicos/1-pre-cadastro-categorias.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 2,
          title: "2 - Criando Características de Produto",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Estrat%C3%A9gicos/2-pre-cadastro-caracteristicas.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 3,
          title: "3 - Entendendo Derivações de Produto",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Estrat%C3%A9gicos/3-pre-cadastro-derivacoes.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 4,
          title: "4 - Cadastro de Marcas",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Estrat%C3%A9gicos/4-pre-cadastro-marcas.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 5,
          title: "5 - Mão na Massa",
          url: "",
          showQuiz: false,
          showChecklist: true,
          checklistTitle: "Agora chegou a hora de realizar um pré-cadastro :",
          checklist: {
            items: ["Faça a criação de categorias", "Crie características para o produto", "Crie uma derivação Exemplo","Crie marcas para os seus produtos"],
          },
        },
      ],
    },
    {
      title: "Cadastro de Produto",
      videos: [
        {
          id: 6,
          title: "1 - Base do Produto",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Estrat%C3%A9gicos/5-cadastro-step-1-base.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 7,
          title: "2 - Tributação e Dimensão",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Estrat%C3%A9gicos/6-cadastro-step-2-tributacao%20e%20dimensao.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 8,
          title: "3 - Derivações do Produto",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Estrat%C3%A9gicos/7-cadastro-step-3-derivaçoes.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 9,
          title: "4 - Configuração das Mídias",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Estrat%C3%A9gicos/8-cadastro-step-4-midias.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 10,
          title: "5 - Inserindo Características no Produto",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Estrat%C3%A9gicos/9-cadastro-step-5-caracteristicas.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 11,
          title: "6 - Descrição Geral",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Estrat%C3%A9gicos/10-cadastro-step-6-descricao-geral.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 12,
          title: "7 - Configuração de SEO",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Estrat%C3%A9gicos/11-cadastro-step-7-seo.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 13,
          title: "8 - Inserindo Estoque no Produto",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Estrat%C3%A9gicos/12-cadastro-step-8-estoque.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 14,
          title: "9 - Inserindo Preço no Produto",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Estrat%C3%A9gicos/13-cadastro-step-9-preco-final.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 15,
          title: "5 - Mão na massa",
          url: "",
          showQuiz: false,
          showChecklist: true,
          checklistTitle: "Cadastre seus dois produtos principais seguindo as orientações dos vídeos :",
          checklist: {
            items: ["Faça a criação do produto base", "Faça a criação do produto derivação", "Insira valor nesse produto","Insira estoque no produto"],
          },
        },
        {
          id : 16,
          title: 'Conclusão do Módulo',
          certificate: true,
        }
      ],
    },
  ],
};

function Estrategico() {
  const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
  const [currentVideoId, setCurrentVideoId] = useState(1);
  const [activeSection, setActiveSection] = useState(0);
  const [quizActive, setQuizActive] = useState(false);
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [quizReasons, setQuizReasons] = useState([]);
  const [checklistActive, setChecklistActive] = useState(false);
  const [checklistItems, setChecklistItems] = useState([]);
  const [checklistTitle, setChecklistTitle] = useState("");
  const [certificateActive, setCertificateActive] = useState(false);

  const handleVideoSelect = (
    videoUrl,
    videoId,
    showQuiz,
    showChecklist,
    checklist,
    checklistTitle,
    certificate
  ) => {
    if (currentVideoId === videoId) {
      return;
    }

    setSelectedVideoUrl(videoUrl);
    setCurrentVideoId(videoId);

    if (showQuiz) {
      if (!quizActive) {
        setQuizActive(true);
        setChecklistActive(false);
        setCertificateActive(false);
        const selectedVideo = videoSections.sections
          .flatMap((section) => section.videos)
          .find((video) => video.id === videoId);

        if (selectedVideo && selectedVideo.quiz) {
          setQuizQuestions(selectedVideo.quiz.questions);
          setQuizReasons(
            selectedVideo.quiz.questions.map((question) => question.reasons)
          );
        } else {
          setQuizQuestions([]);
          setQuizReasons([]);
        }
      }
    } else if (showChecklist) {
      if (!checklistActive) {
        setChecklistActive(true);
        setQuizActive(false);
        setCertificateActive(false);
        setChecklistItems(checklist.items);
        setChecklistTitle(checklistTitle);
      }
    } else if (certificate) {
      setCertificateActive(true);
      setQuizActive(false);
      setChecklistActive(false);
    } else {
      setQuizActive(false);
      setChecklistActive(false);
      setCertificateActive(false);
      setQuizQuestions([]);
      setQuizReasons([]);
      setChecklistItems([]);
      setChecklistTitle("");
    }
  };

  const handleNextVideo = () => {
    const currentSectionIndex = videoSections.sections.findIndex((section) =>
      section.videos.some((video) => video.id === currentVideoId)
    );
    const currentSection = videoSections.sections[currentSectionIndex];
    const currentVideoIndex = currentSection.videos.findIndex(
      (video) => video.id === currentVideoId
    );

    if (currentVideoIndex < currentSection.videos.length - 1) {
      const nextVideo = currentSection.videos[currentVideoIndex + 1];
      handleVideoSelect(
        nextVideo.url,
        nextVideo.id,
        nextVideo.showQuiz,
        nextVideo.showChecklist,
        nextVideo.checklist,
        nextVideo.checklistTitle,
        nextVideo.certificate
      );
    } else if (currentSectionIndex < videoSections.sections.length - 1) {
      const nextSection = videoSections.sections[currentSectionIndex + 1];
      const firstVideoOfNextSection = nextSection.videos[0];
      handleVideoSelect(
        firstVideoOfNextSection.url,
        firstVideoOfNextSection.id,
        firstVideoOfNextSection.showQuiz,
        firstVideoOfNextSection.showChecklist,
        firstVideoOfNextSection.checklist,
        firstVideoOfNextSection.checklistTitle,
        firstVideoOfNextSection.certificate
      );
      setActiveSection(currentSectionIndex + 1);
    }
  };

  const handlePreviousVideo = () => {
    const currentSectionIndex = videoSections.sections.findIndex((section) =>
      section.videos.some((video) => video.id === currentVideoId)
    );
    const currentSection = videoSections.sections[currentSectionIndex];
    const currentVideoIndex = currentSection.videos.findIndex(
      (video) => video.id === currentVideoId
    );

    if (currentVideoIndex > 0) {
      const previousVideo = currentSection.videos[currentVideoIndex - 1];
      handleVideoSelect(
        previousVideo.url,
        previousVideo.id,
        previousVideo.showQuiz,
        previousVideo.showChecklist,
        previousVideo.checklist,
        previousVideo.checklistTitle,
        previousVideo.certificate
      );
    } else if (currentSectionIndex > 0) {
      const previousSection = videoSections.sections[currentSectionIndex - 1];
      const lastVideoOfPreviousSection =
        previousSection.videos[previousSection.videos.length - 1];
      handleVideoSelect(
        lastVideoOfPreviousSection.url,
        lastVideoOfPreviousSection.id,
        lastVideoOfPreviousSection.showQuiz,
        lastVideoOfPreviousSection.showChecklist,
        lastVideoOfPreviousSection.checklist,
        lastVideoOfPreviousSection.checklistTitle,
        lastVideoOfPreviousSection.certificate
      );
      setActiveSection(currentSectionIndex - 1);
    }
  };

  const handleQuizComplete = () => {
    setQuizActive(false);
    handleNextVideo();
  };

  const handleChecklistComplete = () => {
    setChecklistActive(false);
    handleNextVideo()
  };

  useEffect(() => {
    const currentVideo = videoSections.sections
      .flatMap((section) => section.videos)
      .find((video) => video.id === currentVideoId);
    if (currentVideo) {
      setSelectedVideoUrl(currentVideo.url);
      setQuizActive(currentVideo.showQuiz);
      setChecklistActive(currentVideo.showChecklist);
      setCertificateActive(currentVideo.certificate === true);
      if (currentVideo.checklist) {
        setChecklistItems(currentVideo.checklist.items);
        setChecklistTitle(currentVideo.checklistTitle);
      } else {
        setChecklistItems([]);
        setChecklistTitle("");
      }
      setQuizQuestions(currentVideo.quiz ? currentVideo.quiz.questions : []);
      setQuizReasons(
        currentVideo.quiz
          ? currentVideo.quiz.questions.map((question) => question.reasons)
          : []
      );
    }
  }, [currentVideoId]);

  return (
    <div className="container-cp">
      <HomeIcon />
      <Sidebar
        sections={videoSections.sections}
        onVideoSelect={handleVideoSelect}
        currentVideoId={currentVideoId}
        activeSection={activeSection}
        setActiveSection={setActiveSection}
      />

      {quizActive ? (
        <Quiz
          questions={quizQuestions}
          reasons={quizReasons}
          onQuizComplete={handleQuizComplete}
        />
      ) : checklistActive ? (
        <Checklist
          title={checklistTitle}
          items={checklistItems}
          onChecklistComplete={handleChecklistComplete}
        />
      ) : certificateActive ? (
        <Certificate moduleName="Cadastro de Produto" />
      ) : (
        <VideoPlayer
          videoUrl={selectedVideoUrl}
          onNextClick={handleNextVideo}
          onBackClick={handlePreviousVideo}
        />
      )}

    </div>
  );
}

export default Estrategico;

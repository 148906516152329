import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import VideoPlayer from "../../components/VideoPlayer";
import Footer from "../../components/Footer/footer";
import Quiz from "../../components/Quiz/Quiz";
import Checklist from "../../components/Checklist/Checklist";
import HomeIcon from "../../components/HomeIcon/HomeIcon";
import Certificate from "../../components/Certificate/Certificate";
import "./Joias.css";
import { Navigate } from "react-router-dom";

const videoSections = {
  sections: [
    {
      title: "Pré Cadastro",
      videos: [
        {
          id: 1,
          title: "1 - Categorias",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/J%C3%B3ias/pre-cadastro-categorias.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 2,
          title: "2 - Características",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/J%C3%B3ias/pre-cadastro-caracteristicas.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 3,
          title: "3 - Marcas",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/J%C3%B3ias/pre-cadastro-marcas.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 4,
          title: "4 - Derivações",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/J%C3%B3ias/pre-cadastro-derivacoes.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 5,
          title: "Quiz",
          url: "",
          showQuiz: true,
          quizId: 1,
          quiz: {
            questions: [
              {
                question: "Por que é importante cadastrarmos boas características?",
                answers: [
                  "Não possui funções profundas, apenas estética",
                  "Não agrega no produto",
                  "É importante para o site, portanto não auxilia nos marketplaces",
                  "É extremamente importante, pois auxilia em trafego orgânico e tambem reduz o retrabalho ao enviar anúncios ao marketplace",
                ],
                correct: 3,
                reasons: [
                  "Opção A é incorreta porque cadastrar boas características não é apenas uma questão estética, mas também funcional, ajudando na visibilidade e eficiência das vendas.",
                  "Opção B é incorreta porque cadastrar boas características agrega valor ao produto, melhorando a sua apresentação e atratividade.",
                  "Opção C é incorreta porque cadastrar boas características auxilia tanto no site quanto nos marketplaces, melhorando a experiência do usuário e a performance das vendas.",
                  "Parabéns! Cadastrar boas características é extremamente importante, pois auxilia no tráfego orgânico e também reduz o retrabalho ao enviar anúncios ao marketplace. Isso melhora a visibilidade do produto, facilita o processo de vendas e aumenta a eficiência operacional.",
                ],
              },
            ],
          },
        },
        {
          id: 6,
          title: "Mão na Massa",
          url: "",
          showQuiz: false,
          showChecklist: true,
          checklistTitle: "Realize o Checklist de Pré Cadastro",
          checklist: {
            items: ["Criar Categorias", "Criar Características", "Criar Marca"],
          },
        },
      ],
    },
    {
      title: "Cadastro de Produto",
      videos: [
        {
          id: 7,
          title: "1 - Base",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/J%C3%B3ias/cadastro-step-1-base.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 8,
          title: "2 - Tributação e Dimensão",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/J%C3%B3ias/cadastro-step-2-tributacao%20e%20dimensao.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 9,
          title: "3 - Derivações",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/J%C3%B3ias/cadastro-step-3-deriva%C3%A7oes.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 10,
          title: "4 - Mídias",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/J%C3%B3ias/cadastro-step-4-midias.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 11,
          title: "Quiz",
          url: "",
          showQuiz: true,
          quizId: 1,
          quiz: {
            questions: [
              {
                question: "Qual o tamanho ideal para as Mídias ?",
                answers: [
                  "Recomendamos utilizar 600x600px e acima de 4mb",
                  "Recomendamos utilizar 890x890px e no máximo 1mb",
                  "Recomendamos utilizar 1000x1000px e acima de 4mb",
                  "Recomendamos utilizar 1000x1000px a no máximo 1200x1200, com no máximo 1mb",
                ],
                correct: 3,
                reasons: [
                  "Opção A é incorreta porque o tamanho de 600x600px é muito pequeno e o tamanho do arquivo acima de 4MB é muito grande, o que pode prejudicar o carregamento e a qualidade da imagem.",
                  "Opção B é incorreta porque, embora o tamanho do arquivo esteja adequado (no máximo 1MB), a resolução de 890x890 é menor que o ideal recomendado para manter a qualidade das imagens em diversas plataformas.",
                  "Opção C é incorreta porque, embora a resolução de 1000x1000px esteja adequada, o tamanho do arquivo acima de 4MB é muito grande, o que pode prejudicar o carregamento da página.",
                  "Parabéns! A resposta correta é a Opção D. Recomendamos utilizar imagens de 1000x1000px a no máximo 1200x1200px, com um tamanho de arquivo não superior a 1MB. Isso garante uma boa qualidade de imagem sem comprometer o tempo de carregamento da página.",
                ],
              },
            ],
          },
        },
        {
          id: 12,
          title: "5 - Características",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/J%C3%B3ias/cadastro-step-5-caracteristicas.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 13,
          title: "6 - Descrição Geral",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/J%C3%B3ias/cadastro-step-6-descricao-geral.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 14,
          title: "7 - SEO",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/J%C3%B3ias/cadastro-step-7-seo.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 15,
          title: "8 - Preço Individual",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/J%C3%B3ias/como-colocar-preco-de-forma-manual.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 16,
          title: "9 - Estoque individual",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/J%C3%B3ias/colocar-estoque-de-forma-manual.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 17,
          title: "10 - Produto Final",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/J%C3%B3ias/apresentacao-do-produto-final.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 18,
          title: "11 - Preço em Lote",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/J%C3%B3ias/como-colocar-preco-via-planilhas-em-lote.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 19,
          title: "12 - Estoque em Lote",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/J%C3%B3ias/colocar-estoque-via-planilhas.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 20,
          title: "13 - Como Replicar Produto",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/J%C3%B3ias/como-replicar-produtos.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 21,
          title: "Mão na Massa",
          url: "",
          showQuiz: false,
          showChecklist: true,
          checklistTitle: "Realize o Checklist de Cadastro de Produto",
          checklist: {
            items: ["Cadastrar um Produto", "Acionar o Analista na Monday"],
          },
        },
        {
          id: 22,
          title: 'Conclusão do Módulo',
          certificate: true,
        }
      ],
    },
  ],
};

function Joias() {
  const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
  const [currentVideoId, setCurrentVideoId] = useState(1);
  const [activeSection, setActiveSection] = useState(0);
  const [quizActive, setQuizActive] = useState(false);
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [quizReasons, setQuizReasons] = useState([]);
  const [checklistActive, setChecklistActive] = useState(false);
  const [checklistItems, setChecklistItems] = useState([]);
  const [checklistTitle, setChecklistTitle] = useState("");
  const [certificateActive, setCertificateActive] = useState(false);
  const [certificateUrl, setCertificateUrl] = useState(null); 


  const handleVideoSelect = (
    videoUrl,
    videoId,
    showQuiz,
    showChecklist,
    checklist,
    checklistTitle,
    certificate
  ) => {
    if (currentVideoId === videoId) {
      return;
    }

    setSelectedVideoUrl(videoUrl);
    setCurrentVideoId(videoId);

    if (showQuiz) {
      if (!quizActive) {
        setQuizActive(true);
        setChecklistActive(false);
        setCertificateActive(false);
        const selectedVideo = videoSections.sections
          .flatMap((section) => section.videos)
          .find((video) => video.id === videoId);

        if (selectedVideo && selectedVideo.quiz) {
          setQuizQuestions(selectedVideo.quiz.questions);
          setQuizReasons(
            selectedVideo.quiz.questions.map((question) => question.reasons)
          );
        } else {
          setQuizQuestions([]);
          setQuizReasons([]);
        }
      }
    } else if (showChecklist) {
      if (!checklistActive) {
        setChecklistActive(true);
        setQuizActive(false);
        setCertificateActive(false);
        setChecklistItems(checklist.items);
        setChecklistTitle(checklistTitle);
      }
    } else if (certificate) {
      setCertificateActive(true);
      setCertificateUrl(certificate.certificateUrl);
      setQuizActive(false);
      setChecklistActive(false);
      const selectedVideo = videoSections.sections
        .flatMap((section) => section.videos)
        .find((video) => video.id === videoId);
      if (selectedVideo && selectedVideo.certificateUrl) {
        setCertificateUrl(selectedVideo.certificateUrl);
      } else {
        setCertificateUrl(null);
      }
    } else {
      setQuizActive(false);
      setChecklistActive(false);
      setCertificateActive(false);
      setQuizQuestions([]);
      setQuizReasons([]);
      setChecklistItems([]);
      setChecklistTitle("");
      setCertificateUrl(null);
    }
  };
  const handleNextVideo = () => {
    const currentSectionIndex = videoSections.sections.findIndex((section) =>
      section.videos.some((video) => video.id === currentVideoId)
    );
    const currentSection = videoSections.sections[currentSectionIndex];
    const currentVideoIndex = currentSection.videos.findIndex(
      (video) => video.id === currentVideoId
    );

    if (currentVideoIndex < currentSection.videos.length - 1) {
      const nextVideo = currentSection.videos[currentVideoIndex + 1];
      handleVideoSelect(
        nextVideo.url,
        nextVideo.id,
        nextVideo.showQuiz,
        nextVideo.showChecklist,
        nextVideo.checklist,
        nextVideo.checklistTitle,
        nextVideo.certificate
      );
    } else if (currentSectionIndex < videoSections.sections.length - 1) {
      const nextSection = videoSections.sections[currentSectionIndex + 1];
      const firstVideoOfNextSection = nextSection.videos[0];
      handleVideoSelect(
        firstVideoOfNextSection.url,
        firstVideoOfNextSection.id,
        firstVideoOfNextSection.showQuiz,
        firstVideoOfNextSection.showChecklist,
        firstVideoOfNextSection.checklist,
        firstVideoOfNextSection.checklistTitle,
        firstVideoOfNextSection.certificate
      );
      setActiveSection(currentSectionIndex + 1);
    }
  };

  const handlePreviousVideo = () => {
    const currentSectionIndex = videoSections.sections.findIndex((section) =>
      section.videos.some((video) => video.id === currentVideoId)
    );
    const currentSection = videoSections.sections[currentSectionIndex];
    const currentVideoIndex = currentSection.videos.findIndex(
      (video) => video.id === currentVideoId
    );

    if (currentVideoIndex > 0) {
      const previousVideo = currentSection.videos[currentVideoIndex - 1];
      handleVideoSelect(
        previousVideo.url,
        previousVideo.id,
        previousVideo.showQuiz,
        previousVideo.showChecklist,
        previousVideo.checklist,
        previousVideo.checklistTitle,
        previousVideo.certificate
      );
    } else if (currentSectionIndex > 0) {
      const previousSection = videoSections.sections[currentSectionIndex - 1];
      const lastVideoOfPreviousSection =
        previousSection.videos[previousSection.videos.length - 1];
      handleVideoSelect(
        lastVideoOfPreviousSection.url,
        lastVideoOfPreviousSection.id,
        lastVideoOfPreviousSection.showQuiz,
        lastVideoOfPreviousSection.showChecklist,
        lastVideoOfPreviousSection.checklist,
        lastVideoOfPreviousSection.checklistTitle,
        lastVideoOfPreviousSection.certificate
      );
      setActiveSection(currentSectionIndex - 1);
    }
  };

  const handleQuizComplete = () => {
    setQuizActive(false);
    handleNextVideo();
  };

  const handleChecklistComplete = () => {
    setChecklistActive(false);
    handleNextVideo();
  };


  useEffect(() => {
    const currentVideo = videoSections.sections
      .flatMap((section) => section.videos)
      .find((video) => video.id === currentVideoId);
    if (currentVideo) {
      setSelectedVideoUrl(currentVideo.url);
      setQuizActive(currentVideo.showQuiz);
      setChecklistActive(currentVideo.showChecklist);
      setCertificateActive(currentVideo.certificate === true);
      if (currentVideo.checklist) {
        setChecklistItems(currentVideo.checklist.items);
        setChecklistTitle(currentVideo.checklistTitle);
      } else {
        setChecklistItems([]);
        setChecklistTitle("");
      }
      setQuizQuestions(currentVideo.quiz ? currentVideo.quiz.questions : []);
      setQuizReasons(
        currentVideo.quiz
          ? currentVideo.quiz.questions.map((question) => question.reasons)
          : []
      );
    }
  }, [currentVideoId]);

  return (
    <div className="container-cp">
      <HomeIcon />
      <Sidebar
        sections={videoSections.sections}
        onVideoSelect={handleVideoSelect}
        currentVideoId={currentVideoId}
        activeSection={activeSection}
        setActiveSection={setActiveSection}
      />

      {quizActive ? (
        <Quiz
          questions={quizQuestions}
          reasons={quizReasons}
          onQuizComplete={handleQuizComplete}
        />
      ) : checklistActive ? (
        <Checklist
          title={checklistTitle}
          items={checklistItems}
          onChecklistComplete={handleChecklistComplete}
        />
      ) : certificateActive ? (
        <Certificate moduleName="Cadastro de Produto" certificateUrl={"https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/unnamed.png"} />
      ) : (
        <VideoPlayer
          videoUrl={selectedVideoUrl}
          onNextClick={handleNextVideo}
          onBackClick={handlePreviousVideo}
        />
      )}

    </div>
  );
}

export default Joias;

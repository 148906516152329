import React from "react";
import FooterSVG from "../../assets/logoMagazord.svg";
import './footer.css'

function Footer() {
  return (
    <footer className="footer">
      <img src={FooterSVG} alt="Magazord" />
    </footer>
  );
}

export default Footer;

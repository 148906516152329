import React from "react";
import "./Sidebar.css";
import ProgressBar from '../ProgressBar/ProgressBar';
import Footer from '../Footer/footer'

function Sidebar({ sections, onVideoSelect, currentVideoId, activeSection, setActiveSection }) {
  const handleVideoClick = (videoUrl, videoId) => {
    onVideoSelect(videoUrl, videoId);
  };

  const toggleSection = (sectionIndex) => {
    setActiveSection(sectionIndex);
  };

  const totalVideos = sections.reduce((acc, section) => acc + section.videos.length, 0);
  const progress = (currentVideoId / totalVideos) * 100;

  return (
    <div className="container">
      <ul>
        
      <ProgressBar currentVideoId={currentVideoId} sections={sections} />
        {sections.map((section, sectionIndex) => (
          <React.Fragment key={sectionIndex}>
            <li
              className={`section-title ${activeSection === sectionIndex ? 'active' : ''}`}
              onClick={() => toggleSection(sectionIndex)}
            >
              {section.title}
            </li>
            {activeSection === sectionIndex && section.videos.map((video, videoIndex) => (
              <li
                key={videoIndex}
                className={`custom-li ${video.id === currentVideoId ? 'active' : ''}`}
                onClick={() => handleVideoClick(video.url, video.id)}
              >
                {video.title}
              </li>
            ))}
            
          </React.Fragment>
        ))}
        
      </ul>
      
      <Footer/>

    </div>
  );
}

export default Sidebar;

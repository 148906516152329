import React from "react";
import "./Certificate.css";
import { useNavigate } from "react-router-dom";
import Congratulations from "../../assets/congratulations.svg";

const Certificate = ({ moduleName, certificateUrl }) => {
  const navigate = useNavigate();
  const title = `Parabéns! Você concluiu o módulo ${moduleName}`;

  const handleBackToHome = () => {
    navigate("/");
  };

  const handleOpenCertificate = () => {
    if (certificateUrl) {
      window.open(certificateUrl);
    } else {
      alert("O URL do certificado não está definido.");
    }
  };

  return (
    <div className="certificate-container">
      <div className="certificate-content">
        <h2>{title}</h2>
        <p>Cada vez mais, você está pronto para lançar seu e-commerce!</p>
        <img src={Congratulations} alt="Magazord" />

          <button className="button-home" onClick={handleBackToHome}>
            Voltar para a página Inicial
          </button>
      </div>
    </div>
  );
};

export default Certificate;

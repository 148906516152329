import { useEffect } from 'react';
import { registerExit } from '../services/api';

const useTrackExit = () => {
  useEffect(() => {
    const updateExitTime = async () => {
      const email = localStorage.getItem('email');
      console.log('E-mail do localStorage:', email);
      
      if (email) {
        try {
          await registerExit(email);
        } catch (error) {
          console.error('Erro ao atualizar a data de saída:', error.message || error);
        }
      } else {
        console.error('E-mail não encontrado no localStorage');
      }
    };

    const setInactivityTimeout = () => {
      clearTimeout(inactivityTimeout);
      inactivityTimeout = setTimeout(updateExitTime, 25 * 60 * 1000); 
      console.log('Temporizador de inatividade reiniciado');
    };

    let inactivityTimeout;
    window.addEventListener('mousemove', setInactivityTimeout);
    window.addEventListener('keydown', setInactivityTimeout);
    window.addEventListener('click', setInactivityTimeout);
    window.addEventListener('scroll', setInactivityTimeout);

    setInactivityTimeout();

    window.addEventListener('beforeunload', async (event) => {
      try {
        console.log('Tentando atualizar a data de saída antes de sair...');
        await updateExitTime();
      } catch (error) {
        console.error('Erro ao atualizar a data de saída:', error.message || error);
      }
    });

    return () => {
      window.removeEventListener('mousemove', setInactivityTimeout);
      window.removeEventListener('keydown', setInactivityTimeout);
      window.removeEventListener('click', setInactivityTimeout);
      window.removeEventListener('scroll', setInactivityTimeout);
      window.removeEventListener('beforeunload', updateExitTime);
      clearTimeout(inactivityTimeout);
    };
  }, []);
};

export default useTrackExit;

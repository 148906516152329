import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import VideoPlayer from "../../components/VideoPlayer";
import Footer from "../../components/Footer/footer";

import Header from '../../components/Header/header';
import Quiz from "../../components/Quiz/Quiz";
import Checklist from "../../components/Checklist/Checklist";
import HomeIcon from "../../components/HomeIcon/HomeIcon";
import Certificate from "../../components/Certificate/Certificate";
import "./Construcao.css";

const videoSections = {
  sections: [
    {
      title: "Pré Cadastro",
      videos: [
        {
          id: 1,
          title: "1 - Apresentando o Produto Base",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Casa-construcao/Pre-cadastro/1-apresentacao-do-produto.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 2,
          title: "2 - Criando as Categorias do Site",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Casa-construcao/Pre-cadastro/2-pre-cadastro-categorias.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 3,
          title: "3 - Para que Servem as Características?",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Casa-construcao/Pre-cadastro/3-pre-cadastro-caracteristicas.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 4,
          title: "4 - Cadastrando Derivações de Produto",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Casa-construcao/Pre-cadastro/4-pre-cadastro-derivacoes.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 5,
          title: "5 - Cadastrando Marcas Para o Produto",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Casa-construcao/Pre-cadastro/5-pre-cadastro-marcas.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 6,
          title: "Quiz",
          url: "",
          showQuiz: true,
          quizId: 1,
          quiz: {
            questions: [
              {
                question: "Quantas derivações podemos utilizar no cadastro Magazord?",
                answers: [
                  "A - Apenas uma",
                  "B - De duas à três derivações ",
                  "C - No máximo duas derivações",
                  "D - Quantas forem necessárias",
                ],
                correct: 2,
                reasons: [
                  "Incorreto! No Magazord podemos ter no máximo duas derivações de produto",
                  "Incorreto! No Magazord podemos ter no máximo duas derivações de produto",
                  "Parabéns, a resposta está correta! Podemos ter apenas duas derivações no produto",
                  "Incorreto! No Magazord podemos ter no máximo duas derivações de produto",
                ],
              },
            ],
          },
        },
        {
          id: 7,
          title: "Faça você mesmo !",
          url: "",
          showQuiz: false,
          showChecklist: true,
          checklistTitle:
            "Chegou a sua vez de aplicar o que aprendeu nos vídeos. Mão na massa !",
          checklist: {
            items: [
              "Entenda as Derivações",
              "Crie e organize suas categorias",
              "Crie cinco Caracteristicas para seu produto",
              "Crie uma marca"
            ],
          },
        },
      ],
    },
    {
      title: "Cadastro de Produto",
      videos: [
        {
          id: 8,
          title: "1 - Base do Produto",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Casa-construcao/CadastroProduto/6-cadastro-step-1-base.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 9,
          title: "Quiz",
          url: "",
          showQuiz: true,
          quizId: 1,
          quiz: {
            questions: [
              {
                question: "Na base do cadastro de produtos, quais são as regras para preencher o campo Código/SKU do Produto? ",
                answers: [
                  "A - Posso utilizar qualquer símbolo, letra ou número",
                  "B - Posso utilizar letras, números e não ultrapassar 13 caracteres",
                  "C - Apenas letras minúsculas",
                  "D - Apenas números ",
                ],
                correct: 1,
                reasons: [
                  "Incorreto! O código Sku do produto, pode conter letras, números e não ultrapassar os 13 caracteres",
                  "Parabéns, você acertou! Podemos cadastrar o código do nosso produto usando letras, números e ficando abaixo de 13 caractéres",
                  "Incorreto! O código SkU do produto, pode conter letras, números e não ultrapassar os 13 caracteres",
                  "Incorreto! O código SkU do produto, pode conter letras, números e não ultrapassar os 13 caracteres",
                ],
              },
            ],
          },
        },
        {
          id: 10,
          title: "2 - Tributação e Dimensão",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Casa-construcao/CadastroProduto/7-cadastro-step-2-tributacao%20e%20dimensao.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 11,
          title: "3 - Derivações do Produto",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Casa-construcao/CadastroProduto/8-cadastro-step-3-derivaçoes.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 12,
          title: "Quiz",
          url: "",
          showQuiz: true,
          quizId: 1,
          quiz: {
            questions: [
              {
                question: "Na Etapa 3 (Derivações) posso utilizar o mesmo Código/SKU do Produto para derivações diferentes?",
                answers: [
                  "A - Posso, quando o produto só tem um código, vou utilizar o mesmo",
                  "B - Não, cada derivação deverá ter um Código/SKU",
                  "C - Posso utilizar desde que use caracteres maiúsculos para diferenciar",
                  "D - Posso usar apenas letras ",
                ],
                correct: 1,
                reasons: [
                  "Incorreto! O código SkU do produto, é único para cada derivação dos itens, no qual cada item deverá ter um código",
                  "Parabéns, você acertou! Podemos cadastrar o código do nosso produto usando letras, números e ficando abaixo de 13 caractéres",
                  "Incorreto! O código SkU do produto, é único para cada derivação dos itens, no qual cada item deverá ter um código",
                  "Incorreto! O código SkU do produto, é único para cada derivação dos itens, no qual cada item deverá ter um código",
                ],
              },
            ],
          },
        },
        {
          id: 13,
          title: "4 - Configuração de Mídias",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Casa-construcao/CadastroProduto/9-cadastro-step-4-midias.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 14,
          title: "5 - Inserindo Características no produto",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Casa-construcao/CadastroProduto/10-cadastro-step-5-caracteristicas.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 15,
          title: "6 - Descrição Geral",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Casa-construcao/CadastroProduto/11-cadastro-step-6-descricao-geral.mp4",
          showQuiz: false,
          showChecklist: false,
        },

        {
          id: 16,
          title: "7 - Configuração de SEO",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Casa-construcao/CadastroProduto/12-cadastro-step-7-seo.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 17,
          title: "8 - Inserindo preço no Produto",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Casa-construcao/CadastroProduto/13-cadastro-step-8-preco-final.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 18,
          title: "9 - Inserindo estoque no Produto",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Casa-construcao/CadastroProduto/14-cadastro-step-9-estoque.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 19,
          title: "10 - Apresentação final do Produto",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/Casa-construcao/CadastroProduto/15-apresentacao-do-produto-final.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 20,
          title: "Mão na Massa",
          url: "",
          showQuiz: false,
          showChecklist: true,
          checklistTitle: "Chegou a hora de você cadastrar um produto no seu painel. Pratique tudo o que aprendeu nos vídeos !",
          checklist: {
            items: ["Faça a criação de um produto no painel", "Insira preço nesse produto", "Insira estoque nesse produto"]
          },
        },
        {
          id: 21,
          title: 'Conclusão do módulo',
          certificate: true,
        }
      ],
    },
  ],
};

function Construcao() {
  const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
  const [currentVideoId, setCurrentVideoId] = useState(1);
  const [activeSection, setActiveSection] = useState(0);
  const [quizActive, setQuizActive] = useState(false);
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [quizReasons, setQuizReasons] = useState([]);
  const [checklistActive, setChecklistActive] = useState(false);
  const [checklistItems, setChecklistItems] = useState([]);
  const [checklistTitle, setChecklistTitle] = useState("");
  const [certificateActive, setCertificateActive] = useState(false);

  const handleVideoSelect = (
    videoUrl,
    videoId,
    showQuiz,
    showChecklist,
    checklist,
    checklistTitle,
    certificate
  ) => {
    if (currentVideoId === videoId) {
      return;
    }

    setSelectedVideoUrl(videoUrl);
    setCurrentVideoId(videoId);

    if (showQuiz) {
      if (!quizActive) {
        setQuizActive(true);
        setChecklistActive(false);
        setCertificateActive(false);
        const selectedVideo = videoSections.sections
          .flatMap((section) => section.videos)
          .find((video) => video.id === videoId);

        if (selectedVideo && selectedVideo.quiz) {
          setQuizQuestions(selectedVideo.quiz.questions);
          setQuizReasons(
            selectedVideo.quiz.questions.map((question) => question.reasons)
          );
        } else {
          setQuizQuestions([]);
          setQuizReasons([]);
        }
      }
    } else if (showChecklist) {
      if (!checklistActive) {
        setChecklistActive(true);
        setQuizActive(false);
        setCertificateActive(false);
        setChecklistItems(checklist.items);
        setChecklistTitle(checklistTitle);
      }
    } else if (certificate) {
      setCertificateActive(true);
      setQuizActive(false);
      setChecklistActive(false);
    } else {
      setQuizActive(false);
      setChecklistActive(false);
      setCertificateActive(false);
      setQuizQuestions([]);
      setQuizReasons([]);
      setChecklistItems([]);
      setChecklistTitle("");
    }
  };

  const handleNextVideo = () => {
    const currentSectionIndex = videoSections.sections.findIndex((section) =>
      section.videos.some((video) => video.id === currentVideoId)
    );
    const currentSection = videoSections.sections[currentSectionIndex];
    const currentVideoIndex = currentSection.videos.findIndex(
      (video) => video.id === currentVideoId
    );

    if (currentVideoIndex < currentSection.videos.length - 1) {
      const nextVideo = currentSection.videos[currentVideoIndex + 1];
      handleVideoSelect(
        nextVideo.url,
        nextVideo.id,
        nextVideo.showQuiz,
        nextVideo.showChecklist,
        nextVideo.checklist,
        nextVideo.checklistTitle,
        nextVideo.certificate
      );
    } else if (currentSectionIndex < videoSections.sections.length - 1) {
      const nextSection = videoSections.sections[currentSectionIndex + 1];
      const firstVideoOfNextSection = nextSection.videos[0];
      handleVideoSelect(
        firstVideoOfNextSection.url,
        firstVideoOfNextSection.id,
        firstVideoOfNextSection.showQuiz,
        firstVideoOfNextSection.showChecklist,
        firstVideoOfNextSection.checklist,
        firstVideoOfNextSection.checklistTitle,
        firstVideoOfNextSection.certificate
      );
      setActiveSection(currentSectionIndex + 1);
    }
  };

  const handlePreviousVideo = () => {
    const currentSectionIndex = videoSections.sections.findIndex((section) =>
      section.videos.some((video) => video.id === currentVideoId)
    );
    const currentSection = videoSections.sections[currentSectionIndex];
    const currentVideoIndex = currentSection.videos.findIndex(
      (video) => video.id === currentVideoId
    );

    if (currentVideoIndex > 0) {
      const previousVideo = currentSection.videos[currentVideoIndex - 1];
      handleVideoSelect(
        previousVideo.url,
        previousVideo.id,
        previousVideo.showQuiz,
        previousVideo.showChecklist,
        previousVideo.checklist,
        previousVideo.checklistTitle,
        previousVideo.certificate
      );
    } else if (currentSectionIndex > 0) {
      const previousSection = videoSections.sections[currentSectionIndex - 1];
      const lastVideoOfPreviousSection =
        previousSection.videos[previousSection.videos.length - 1];
      handleVideoSelect(
        lastVideoOfPreviousSection.url,
        lastVideoOfPreviousSection.id,
        lastVideoOfPreviousSection.showQuiz,
        lastVideoOfPreviousSection.showChecklist,
        lastVideoOfPreviousSection.checklist,
        lastVideoOfPreviousSection.checklistTitle,
        lastVideoOfPreviousSection.certificate
      );
      setActiveSection(currentSectionIndex - 1);
    }
  };

  const handleQuizComplete = () => {
    setQuizActive(false);
    handleNextVideo();
  };

  const handleChecklistComplete = () => {
    setChecklistActive(false);
    handleNextVideo();
  };

  useEffect(() => {
    const currentVideo = videoSections.sections
      .flatMap((section) => section.videos)
      .find((video) => video.id === currentVideoId);
    if (currentVideo) {
      setSelectedVideoUrl(currentVideo.url);
      setQuizActive(currentVideo.showQuiz);
      setChecklistActive(currentVideo.showChecklist);
      setCertificateActive(currentVideo.certificate === true);
      if (currentVideo.checklist) {
        setChecklistItems(currentVideo.checklist.items);
        setChecklistTitle(currentVideo.checklistTitle);
      } else {
        setChecklistItems([]);
        setChecklistTitle("");
      }
      setQuizQuestions(currentVideo.quiz ? currentVideo.quiz.questions : []);
      setQuizReasons(
        currentVideo.quiz
          ? currentVideo.quiz.questions.map((question) => question.reasons)
          : []
      );
    }
  }, [currentVideoId]);

  return (
    <>
      <div className="container-cp">

        <HomeIcon />
        <Sidebar
          sections={videoSections.sections}
          onVideoSelect={handleVideoSelect}
          currentVideoId={currentVideoId}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
        />


        {quizActive ? (
          <Quiz
            questions={quizQuestions}
            reasons={quizReasons}
            onQuizComplete={handleQuizComplete}
          />
        ) : checklistActive ? (
          <Checklist
            title={checklistTitle}
            items={checklistItems}
            onChecklistComplete={handleChecklistComplete}
          />
        ) : certificateActive ? (
          <Certificate moduleName="Cadastro de Produto" />
        ) : (
          <VideoPlayer
            videoUrl={selectedVideoUrl}
            onNextClick={handleNextVideo}
            onBackClick={handlePreviousVideo}
          />
        )}

      </div>
    </>
  );
}

export default Construcao;

import React from 'react';
import './VideoPlayer.css';

const VideoPlayer = ({ videoUrl, onNextClick, onBackClick }) => {
  return (
    <div className="video-player-container">
      <iframe
        className="video-element"
        src={videoUrl}
        title="Video Player"
        frameBorder="0"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      <div className="video-buttons">
        <button className="buttonBackVideo" onClick={onBackClick}>Vídeo Anterior</button>
        <button className="buttonNextVideo" onClick={onNextClick}>Próximo Vídeo</button>
      </div>
    </div>
  );
};

export default VideoPlayer;

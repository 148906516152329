import React, { useEffect, useState, useMemo } from 'react';
import { listFaq } from '../../services/api';
import FAQList from '../../components/FAQList/FAQList';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header/header';
import { PiSmileySadLight } from "react-icons/pi";
import GifLoading from '../../assets/gif-loading.gif';
import './FAQListPage.css';

const moduleIcons = {
  Onboarding: 'https://s3.amazonaws.com/cdn.freshdesk.com/data/helpdesk/attachments/production/151033266431/original/T6VnLSDLumbm0fTAhc6_JCzqb015SWJ1DA.png',
  'Cadastro de Produto': 'https://s3.amazonaws.com/cdn.freshdesk.com/data/helpdesk/attachments/production/151033258899/original/75OSEas5C1U6zxb8KW8y0XSb2X42_Y7Zgg.png',
  Front: 'https://s3.amazonaws.com/cdn.freshdesk.com/data/helpdesk/attachments/production/151033259300/original/L8GALxpbC-QGj1za_QE-gtbCUw53Lbs3Fg.png',
  Marketing: 'https://s3.amazonaws.com/cdn.freshdesk.com/data/helpdesk/attachments/production/151033261516/original/SwR4GflQoiIIo6XBSdwZ1mb_PNM7d68_fQ.png',
  Pagamentos: 'https://s3.amazonaws.com/cdn.freshdesk.com/data/helpdesk/attachments/production/151033262332/original/HcckvjxGWHI_ZLyBNsueB43lCFfTaIz-ug.png',
  Transporte: 'https://s3.amazonaws.com/cdn.freshdesk.com/data/helpdesk/attachments/production/151033260221/original/xj9dwzuy5J3q-ejQPknQ8LQAfTDQr1OeWg.png',
  Faturamento: 'https://s3.amazonaws.com/cdn.freshdesk.com/data/helpdesk/attachments/production/151033259409/original/AX2grsBStvTDpxfCvi7V-N7rv1zFdA_9Mw.png',
};

const modules = [
  { id: 1, name: 'Onboarding' },
  { id: 2, name: 'Cadastro de Produto' },
  { id: 3, name: 'Front' },
  { id: 4, name: 'Marketing' },
  { id: 5, name: 'Pagamentos' },
  { id: 6, name: 'Transporte' },
  { id: 7, name: 'Faturamento' },
];

const filterFaqs = (faqs, searchTerm) => {
  if (!searchTerm) return faqs;
  const lowerSearchTerm = searchTerm.toLowerCase();
  return faqs.filter(({ question, module }) =>
    question.toLowerCase().includes(lowerSearchTerm) ||
    (module && modules.find(({ id }) => id === module).name.toLowerCase().includes(lowerSearchTerm))
  );
};

const FAQListPage = () => {
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFaqs = async () => {
      if (!token) {
        setError('Token não encontrado. Faça login novamente.');
        setLoading(false);
        return;
      }
      try {
        const fetchedFaqs = await listFaq(token);
        setFaqs(fetchedFaqs);
      } catch {
        setError('Erro ao buscar FAQs. Tente novamente mais tarde.');
      } finally {
        setLoading(false);
      }
    };

    fetchFaqs();
  }, [token]);

  const handleSearch = (term) => setSearchTerm(term);

  const filteredFaqs = useMemo(() => filterFaqs(faqs, searchTerm), [faqs, searchTerm]);

  if (loading) {
    return (
      <div className="loading-container">
        <img src={GifLoading} alt="Carregando..." className="loading-icon" />
        <p>Carregando FAQs...</p>
      </div>
    );
  }
  
  if (error) {
    return <p>{error}</p>;
  }

  const sortedFaqs = [...faqs].sort((a, b) => b.accessCount - a.accessCount); // Ordena FAQs por acessos
  const topFiveFaqs = sortedFaqs.slice(0, 5); // Pega os 5 mais acessados

  return (
    <>
      <Header onSearch={handleSearch} />
      <div className='faq-scroll-container'>
        <div className="faq-page">
          <h1>Perguntas Frequentes: </h1> {/* Adicionando o ícone aqui */}
          {modules.map(({ id, name }) => {
            const moduleFaqs = filteredFaqs.filter(faq => faq.module === id);
            const visibleFaqs = moduleFaqs.slice(0, 5);

            return (
              visibleFaqs.length > 0 && (
                <div key={id} className="module-container">
                  <div className="module-header">
                    <img src={moduleIcons[name]} alt={`Ícone ${name}`} className="module-icon" />
                    <h2>Módulo {name}</h2>
                  </div>
                  <div className="faq-list">
                    <FAQList moduleId={id} faqs={visibleFaqs} />
                  </div>
                  {moduleFaqs.length > 5 && (
                    <a
                      onClick={() => navigate(`/faq/${name.toLowerCase()}`)}
                      className="see-more-link"
                    >
                      Ver mais
                    </a>
                  )}
                </div>
              )
            );
          })}
          {filteredFaqs.length === 0 && (
            <div className="no-results">
              <PiSmileySadLight className="sad-icon" style={{ fontSize: '50px' }} />
              <p>Lamentamos, mas não encontramos nada com este termo.</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FAQListPage;

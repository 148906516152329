import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UserList.css';
import { fetchUsers, updateUserStatus, deleteUser, resetPassword } from '../../services/api'; // Import the new function

const token = localStorage.getItem('token');

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 25; 

  useEffect(() => {
    const getUsers = async () => {
      try {
        const data = await fetchUsers(search);
        setUsers(data);
        setError('');
        setTotalPages(Math.ceil(data.length / pageSize)); 
      } catch (error) {
        handleError(error);
      }
    };

    getUsers();
  }, [search]);

  const handleError = (error) => {
    console.error('Erro:', error);
    if (error.response) {
      setError(`Erro: ${error.response.data.message}`);
    } else if (error.request) {
      setError('Erro de rede. Verifique sua conexão.');
    } else {
      setError('Erro ao conectar com o servidor.');
    }
  };

  const handleStatusToggle = async (email, currentStatus) => {
    try {
      await updateUserStatus(token, email, !currentStatus);
      setUsers(users.map(user => user.email === email ? { ...user, status: !currentStatus } : user));
      setError('');
    } catch (error) {
      handleError(error);
    }
  };

  const handleDelete = async (email) => {
    if (window.confirm('Tem certeza de que deseja deletar este usuário?')) {
      try {
        console.log(`Deletando usuário ${email}`);
        await deleteUser(token, email);
        setUsers(users.filter(user => user.email !== email));
        setSuccess('Usuário excluído com sucesso!');
        setError(''); 
      } catch (error) {
        handleError(error);
      }
    }
  };

  const handleResetPassword = (user) => {
    setSelectedUser(user);
  };

  const handleCloseResetPasswordModal = () => {
    setSelectedUser(null);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const paginatedUsers = users.slice(startIndex, startIndex + pageSize);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className="user-list-container">
      <h1>Lista de Clientes :</h1>
      {error && <div className="error-message">{error}</div>}
      {success && <div className="success-message">{success}</div>}
      <input
        type="text"
        className="search-bar"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Buscar por ID da loja ou e-mail do Usuário "
      />
      <table className="table">
        <thead>
          <tr>
            <th>ID Loja</th>
            <th>Nome da Loja</th>
            <th>Email</th>
            <th>Status</th>
            <th>Admin</th>
            <th>Alterar Senha</th>
            <th>Deletar User</th>
          </tr>
        </thead>
        <tbody>
          {paginatedUsers.map(user => (
            <tr key={user.email}>
              <td>{user.id_loja}</td>
              <td>{user.nome_loja}</td>
              <td>{user.email}</td>
              <td>
                <button
                  onClick={() => handleStatusToggle(user.email, user.status)}
                  className={user.status ? 'active' : 'inactive'}
                >
                  {user.status ? '🟢' : '🔴'} 
                </button>
              </td>
              <td>{user.is_admin ? '✔️' : '❌'}</td> 
              <td>
                <button
                  onClick={() => handleResetPassword(user)}
                  className="reset-password-button"
                >
                  🔄 
                </button>
              </td>
              <td>
                <button
                  onClick={() => handleDelete(user.email)}
                  className="delete-button"
                >
                  🗑️ 
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination-controls">
        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>⏮️</button>
        <span>Página {currentPage} de {totalPages}</span>
        <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>⏭️</button>
      </div>

      {selectedUser && (
        <ResetPasswordModal
          user={selectedUser}
          onClose={handleCloseResetPasswordModal}
          onSuccess={() => {
            setSuccess('Senha alterada com sucesso!');
            setSelectedUser(null);
          }}
          onError={(message) => {
            setError(message);
            setSelectedUser(null);
          }}
        />
      )}
    </div>
  );
};

const ResetPasswordModal = ({ user, onClose, onSuccess, onError }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    
    return (
      password.length >= minLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasSpecialChar
    );
  };

  const handleResetPassword = async () => {
    setPasswordError('');

    if (newPassword !== confirmPassword) {
      onError('As senhas não correspondem.');
      return;
    }

    if (!validatePassword(newPassword)) {
      setPasswordError('A nova senha deve ter pelo menos 8 caracteres, uma letra maiúscula, uma letra minúscula e um caractere especial.');
      return;
    }

    const token = localStorage.getItem('token');
    if (!token) {
      onError('Token não encontrado');
      return;
    }

    try {
      console.log('Enviando solicitação para redefinir a senha');
      const response = await resetPassword(token, user.email, newPassword); // Usando a função resetPassword
      console.log('Resposta da API de redefinição de senha:', response);
      onSuccess();
    } catch (error) {
      handleError(error, onError);
    }
  };

  const handleError = (error, onError) => {
    if (error.response) {
      console.error('Erro na resposta da API:', error.response.status, error.response.data);
      onError(error.response.data.message);
    } else if (error.request) {
      console.error('Nenhuma resposta recebida da API:', error.request);
      onError('Erro ao conectar com o servidor. Verifique sua conexão.');
    } else {
      console.error('Erro ao configurar a requisição:', error.message);
      onError('Erro ao conectar com o servidor. Verifique sua conexão.');
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Alterar Senha para {user.email}</h2>
        <input
          type="password"
          placeholder="Nova Senha"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <input
          type="password"
          placeholder="Confirmar Nova Senha"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {passwordError && <div className="error-message">{passwordError}</div>}
        <button onClick={handleResetPassword}>Alterar Senha</button>
        <button onClick={onClose}>Fechar</button>
      </div>
    </div>
  );
};

export default UserList;

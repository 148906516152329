import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { checkAdminStatus } from '../../services/api';

const ProtectedRoute = ({ element: Component, adminOnly = false, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const token = localStorage.getItem('token');

  useEffect(() => {
    const checkAuth = async () => {
      if (!token) {
        setLoading(false);
        return;
      }

      try {
        const response = await checkAdminStatus(token); 

        setIsAuthenticated(true);
        setIsAdmin(response.isAdmin); 
      } catch (error) {
        console.error('Erro ao verificar o status do administrador:', error);
        setIsAuthenticated(false);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, [token]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (adminOnly && !isAdmin) {
    return <Navigate to="/" />;
  }



  return <Component {...rest} />;
};

export default ProtectedRoute;

import React from 'react';
import './CardHeader.css';

function CardHeader({ nome, media }) {
    return (
        <div className="modulo-header">
            {media && (
                <div className="modulo-media">
                    <img src={media} alt={nome} />
                </div>
            )}
        </div>
    );
}

export default CardHeader;
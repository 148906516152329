import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import VideoPlayer from "../../components/VideoPlayer";
import Quiz from "../../components/Quiz/Quiz";
import Checklist from "../../components/Checklist/Checklist";
import HomeIcon from "../../components/HomeIcon/HomeIcon";
import Certificate from "../../components/Certificate/Certificate";
import "./Moveis.css";

const videoSections = {
  sections: [
  
    {
      title: "Pré Cadastro",
      videos: [
        {
          id: 1,
          title: "1 - Apresentando o Produto Base",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/M%C3%B3veis/1%20Apresenta%C3%A7%C3%A3o%20do%20produto.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 2,
          title: "2 - Criando as Categorias do Site",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/M%C3%B3veis/2categorias.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 3,
          title: "Quiz",
          url: "",
          showQuiz: true,
          quizId: 1,
          quiz: {
            questions: [
              {
                question: "Sobre a obrigatoriedade de selecionar a categoria no cadastro do produto e sua importância, qual das seguintes afirmações está correta ?",
                answers: [
                  "A- É opcional selecionar a categoria no cadastro de produto, mas caso selecionado, é importante para o controle de estoque",
                  "B- É obrigatório selecionar a categoria no cadastro de produto, sendo de extrema importância vincular o produto à categoria correta para facilitar a organização do e-commerce e a busca pelo cliente final no site",
                  "C- É obrigatório selecionar a categoria no cadastro de produto, porém, não é muito relevante selecionar a categoria correta do produto",
                  "D- É obrigatório selecionar a categoria no cadastro de produto, mas isso não afeta o resultado final",
                ],
                correct: 1,
                reasons: [
                  "Incorreto! A seleção da categoria é obrigatória no cadastro, pois com ela você consegue facilitar a organização do e-commerce e a busca pelo cliente final no site",
                  "Parabens você Acertou! !! A categoria é obrigatória e é de extrema importancia fazer a criação da mesma",
                  "Incorreto! A seleção da categoria é obrigatória no cadastro, pois com ela você consegue facilitar a organização do e-commerce e a busca pelo cliente final no site",
                  "Incorreto! A seleção da categoria é obrigatória no cadastro, pois com ela você consegue facilitar a organização do e-commerce e a busca pelo cliente final no site",
                ],
              },
            ],
          },
        },
        {
          id: 4,
          title: "3 - Para que Servem as Características ?",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/M%C3%B3veis/3%20Criação%20de%20Características.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 5,
          title: "Quiz",
          url: "",
          showQuiz: true,
          quizId: 1,
          quiz: {
            questions: [
              {
                question: "Determinado dia, João decidiu comprar uma cadeira pela internet para sua sala de estar. Ao encontrar o produto desejado em um site, ele buscou detalhar as informações sobre o material, tipo de tecido, altura, entre outros detalhes, mas não encontrou essas informações disponíveis. Por isso, João decidiu não efetuar a compra. Considerando essa situação, sobre a importância de fornecer informações detalhadas no anúncio de produtos, é correto afirmar que:",
                answers: [
                  "A- Informações como estas, realmente não sãso necessárias, pois o cliente irá comprar o produto apenas pela imagem cadastrada no produto",
                  "B- O cliente foi exigente demais, procurando por informações além do necessário",
                  "C- É de extrema importância cadastrar essas informações no cadastro do produto, pois as características fornecem detalhes cruciais sobre o produto",
                  "D- Criar características não é relevante",
                ],
                correct: 2,
                reasons: [
                  "A opção A está incorreta! É de extrema importância darmos ênfase nas características do produto para que o cliente tenha todas as informações necessárias para efetuar a compra",
                  "A opção B está incorreta! É de extrema importância darmos ênfase nas características do produto para que o cliente tenha todas as informações necessárias para efetuar a compra",
                  "Parabéns, Você acertou, as características são um ponto de extrema importância no cadastro de produto, pois com elas podemos dar mais informações sobre ele para o nosso cliente",
                  "A opção D está incorreta! É de extrema importância sdarmos ênfase nas características do produto para que o cliente tenha todas as informações necessárias para efetuar a compra",
                ],
              },
            ],
          },
        },
        {
          id: 6,
          title: "4 - Criandos Novas Derivações",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/M%C3%B3veis/4-derivacoes.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 7,
          title: "Mão na Massa",
          url: "",
          showQuiz: false,
          showChecklist: true,
          checklistTitle: "Agora chegou a hora de você realizar no seu painel o que aprendeu com nossas aulas!",
          checklist: {
            items: ["Faça a criação das Categorias no seu painel", "Faça a criação das Características no seu painel","Crie uma derivação teste no seu painel"]
          },
        },
      ],
    },
    {
      title: "Cadastro de Produto",
      videos: [
        {
          id: 8,
          title: "1 - Base do Produto",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/M%C3%B3veis/5%20-%20step%201.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 9,
          title: "2 - Tributação e Dimensão",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/M%C3%B3veis/6%20-%20step%202.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 10,
          title: "3 - Derivações do Produto",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/M%C3%B3veis/7%20-%20step%203.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 11,
          title: "4 - Configuração das Mídias",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/M%C3%B3veis/8%20-%20step%204.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 12,
          title: "Quiz",
          url: "",
          showQuiz: true,
          quizId: 1,
          quiz: {
            questions: [
              {
                question: "Qual das seguintes opções está correta sobre a quantidade ideal que indicamos, referente a imagens necessárias para um anúncio de produto?",
                answers: [
                  "A- Não precisa de imagem, uma boa descrição já basta",
                  "B- Apenas uma imagem é o suficiente",
                  "C- Três imagens em diferentes perspectivas é o correto",
                  "D- Nenhuma das alternativas",
                ],
                correct: 2,
                reasons: [
                  "A opção A está incorreta! Boas imagens são um ponto de extrema importância em um e-commerce, como as características, através das imagens o cliente consegue ter uma dimensão e uma avaliação melhor sobre o produto",
                  "A opção B está incorreta! Boas imagens são um ponto de extrema importância em um e-commerce, como as características, através das imagens o cliente consegue ter uma dimensão e uma avaliação melhor sobre o produto",
                  "Parabéns, Você acertou! Varias imagens no seu produto só tendem a deixa-lo ainda mais chamativo, invista em imagens de qualidade sobre o produto para garantir o sucesso das suas vendas",
                  "A opção D está incorreta! Boas imagens são um ponto de extrema importância em um e-commerce, como as características, através das imagens o cliente consegue ter uma dimensão e uma avaliação melhor sobre o produto",
                ],
              },
            ],
          },
        },
        {
          id: 13,
          title: "5 - Inserindo Características no Produto",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/M%C3%B3veis/9%20-%20step%205.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 14,
          title: "6 - Descrição Geral",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/M%C3%B3veis/10%20-%20step%206.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        
        {
          id: 15,
          title: "7 - Configuração de SEO",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/M%C3%B3veis/11%20-%20step%207.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 16,
          title: "8 - Inserindo Preço no Produto",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/M%C3%B3veis/12-preco.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 17,
          title: "9 - Inserindo Estoque no Produto",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/M%C3%B3veis/13%20-%20estoque.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 18,
          title: "10 - Apresentação Final do Produto",
          url: "https://magazord-treinamentos.s3.sa-east-1.amazonaws.com/Ava-Magazord/M%C3%B3veis/14%20-%20apresentacao-%20final.mp4",
          showQuiz: false,
          showChecklist: false,
        },
        {
          id: 19,
          title: "Mão na Massa",
          url: "",
          showQuiz: false,
          showChecklist: true,
          checklistTitle: "Cadastre um produto no seu painel usando tudo o que aprendeu nos vídeos !",
          checklist: {
            items: ["Faça a criação de um produto no painel", "Insira preço nesse produto","Insira estoque nesse produto"]
          },
        },
        {
          id : 20,
          title: 'Conclusão do Módulo',
          certificate: true,
        }
      ],
    },
  ],
};

function Moveis() {
  const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
  const [currentVideoId, setCurrentVideoId] = useState(1);
  const [activeSection, setActiveSection] = useState(0);
  const [quizActive, setQuizActive] = useState(false);
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [quizReasons, setQuizReasons] = useState([]);
  const [checklistActive, setChecklistActive] = useState(false);
  const [checklistItems, setChecklistItems] = useState([]);
  const [checklistTitle, setChecklistTitle] = useState("");
  const [certificateActive, setCertificateActive] = useState(false);

  const handleVideoSelect = (
    videoUrl,
    videoId,
    showQuiz,
    showChecklist,
    checklist,
    checklistTitle,
    certificate
  ) => {
    if (currentVideoId === videoId) {
      //console.log("O mesmo vídeo já está selecionado, nenhuma mudança necessária");
      return;
    }

    setSelectedVideoUrl(videoUrl);
    setCurrentVideoId(videoId);

    if (showQuiz) {
      if (!quizActive) {
        setQuizActive(true);
        setChecklistActive(false);
        setCertificateActive(false);
        const selectedVideo = videoSections.sections
          .flatMap((section) => section.videos)
          .find((video) => video.id === videoId);

        if (selectedVideo && selectedVideo.quiz) {
          setQuizQuestions(selectedVideo.quiz.questions);
          setQuizReasons(
            selectedVideo.quiz.questions.map((question) => question.reasons)
          );
        } else {
          setQuizQuestions([]);
          setQuizReasons([]);
        }
      }
    } else if (showChecklist) {
      if (!checklistActive) {
        setChecklistActive(true);
        setQuizActive(false);
        setCertificateActive(false);
        setChecklistItems(checklist.items);
        setChecklistTitle(checklistTitle);
      }
    } else if (certificate) {
      setCertificateActive(true);
      setQuizActive(false);
      setChecklistActive(false);
    } else {
      setQuizActive(false);
      setChecklistActive(false);
      setCertificateActive(false);
      setQuizQuestions([]);
      setQuizReasons([]);
      setChecklistItems([]);
      setChecklistTitle("");
    }
  };

  const handleNextVideo = () => {
    const currentSectionIndex = videoSections.sections.findIndex((section) =>
      section.videos.some((video) => video.id === currentVideoId)
    );
    const currentSection = videoSections.sections[currentSectionIndex];
    const currentVideoIndex = currentSection.videos.findIndex(
      (video) => video.id === currentVideoId
    );

    if (currentVideoIndex < currentSection.videos.length - 1) {
      const nextVideo = currentSection.videos[currentVideoIndex + 1];
      handleVideoSelect(
        nextVideo.url,
        nextVideo.id,
        nextVideo.showQuiz,
        nextVideo.showChecklist,
        nextVideo.checklist,
        nextVideo.checklistTitle,
        nextVideo.certificate
      );
    } else if (currentSectionIndex < videoSections.sections.length - 1) {
      const nextSection = videoSections.sections[currentSectionIndex + 1];
      const firstVideoOfNextSection = nextSection.videos[0];
      handleVideoSelect(
        firstVideoOfNextSection.url,
        firstVideoOfNextSection.id,
        firstVideoOfNextSection.showQuiz,
        firstVideoOfNextSection.showChecklist,
        firstVideoOfNextSection.checklist,
        firstVideoOfNextSection.checklistTitle,
        firstVideoOfNextSection.certificate
      );
      setActiveSection(currentSectionIndex + 1);
    }
  };

  const handlePreviousVideo = () => {
    const currentSectionIndex = videoSections.sections.findIndex((section) =>
      section.videos.some((video) => video.id === currentVideoId)
    );
    const currentSection = videoSections.sections[currentSectionIndex];
    const currentVideoIndex = currentSection.videos.findIndex(
      (video) => video.id === currentVideoId
    );

    if (currentVideoIndex > 0) {
      const previousVideo = currentSection.videos[currentVideoIndex - 1];
      handleVideoSelect(
        previousVideo.url,
        previousVideo.id,
        previousVideo.showQuiz,
        previousVideo.showChecklist,
        previousVideo.checklist,
        previousVideo.checklistTitle,
        previousVideo.certificate
      );
    } else if (currentSectionIndex > 0) {
      const previousSection = videoSections.sections[currentSectionIndex - 1];
      const lastVideoOfPreviousSection =
        previousSection.videos[previousSection.videos.length - 1];
      handleVideoSelect(
        lastVideoOfPreviousSection.url,
        lastVideoOfPreviousSection.id,
        lastVideoOfPreviousSection.showQuiz,
        lastVideoOfPreviousSection.showChecklist,
        lastVideoOfPreviousSection.checklist,
        lastVideoOfPreviousSection.checklistTitle,
        lastVideoOfPreviousSection.certificate
      );
      setActiveSection(currentSectionIndex - 1);
    }
  };

  const handleQuizComplete = () => {
    setQuizActive(false);
    handleNextVideo();
  };

  const handleChecklistComplete = () => {
    setChecklistActive(false);
    handleNextVideo();
  };

  useEffect(() => {
    const currentVideo = videoSections.sections
      .flatMap((section) => section.videos)
      .find((video) => video.id === currentVideoId);
    if (currentVideo) {
      setSelectedVideoUrl(currentVideo.url);
      setQuizActive(currentVideo.showQuiz);
      setChecklistActive(currentVideo.showChecklist);
      setCertificateActive(currentVideo.certificate === true);
      if (currentVideo.checklist) {
        setChecklistItems(currentVideo.checklist.items);
        setChecklistTitle(currentVideo.checklistTitle);
      } else {
        setChecklistItems([]);
        setChecklistTitle("");
      }
      setQuizQuestions(currentVideo.quiz ? currentVideo.quiz.questions : []);
      setQuizReasons(
        currentVideo.quiz
          ? currentVideo.quiz.questions.map((question) => question.reasons)
          : []
      );
    }
  }, [currentVideoId]);

  return (
    <div className="container-cp">
      <HomeIcon />
      <Sidebar
        sections={videoSections.sections}
        onVideoSelect={handleVideoSelect}
        currentVideoId={currentVideoId}
        activeSection={activeSection}
        setActiveSection={setActiveSection}
      />

      {quizActive ? (
        <Quiz
          questions={quizQuestions}
          reasons={quizReasons}
          onQuizComplete={handleQuizComplete}
        />
      ) : checklistActive ? (
        <Checklist
          title={checklistTitle}
          items={checklistItems}
          onChecklistComplete={handleChecklistComplete}
        />
      ) : certificateActive ? (
        <Certificate moduleName="Cadastro de Produto" />
      ) : (
        <VideoPlayer
          videoUrl={selectedVideoUrl}
          onNextClick={handleNextVideo}
          onBackClick={handlePreviousVideo}
        />
      )}

    </div>
  );
}

export default Moveis;

import React from 'react';
import { Link } from 'react-router-dom';
import './AccessButton.css';

function AccessButton({ rota }) {
  return (
    <Link to={rota} className="visualizar-btn">Acessar</Link>
  );
}

export default AccessButton;

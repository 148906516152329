import React, { useState } from "react";
import "./Quiz.css";

const Quiz = ({ questions, reasons, onQuizComplete }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);
  const [reasonForCorrectAnswer, setReasonForCorrectAnswer] = useState("");
  const [reasonForWrongAnswer, setReasonForWrongAnswer] = useState("");
  const [showSendButton, setShowSendButton] = useState(false);
  const [nextButtonText, setNextButtonText] = useState("Enviar Resposta");

  const handleAnswerClick = (index, correct) => {
    if (!showCorrectAnswer) {
      setSelectedAnswerIndex(index);
      setShowCorrectAnswer(false);
      setShowSendButton(true);
    }
  };

  const handleNextQuestion = () => {
    if (selectedAnswerIndex !== null) {
      const nextQuestionIndex = currentQuestionIndex + 1;
      if (nextQuestionIndex < questions.length) {
        setCurrentQuestionIndex(nextQuestionIndex);
        setSelectedAnswerIndex(null);
        setShowCorrectAnswer(false);
        setReasonForCorrectAnswer("");
        setReasonForWrongAnswer("");
        setShowSendButton(false);
        setNextButtonText("Enviar Resposta");
      } else {
        onQuizComplete();
      }
    } else {
      alert(
        "Por favor, selecione uma resposta antes de avançar para a próxima pergunta."
      );
    }
  };

  const currentQuestion = questions[currentQuestionIndex];

  const handleSendAnswer = () => {
    if (selectedAnswerIndex !== null) {
      setShowCorrectAnswer(true);
      setShowSendButton(false);
      setNextButtonText("Continuar");
      if (selectedAnswerIndex === currentQuestion.correct) {
        setReasonForCorrectAnswer(
          reasons[currentQuestionIndex][selectedAnswerIndex]
        );
      } else {
        setReasonForWrongAnswer(
          reasons[currentQuestionIndex][selectedAnswerIndex]
        );
      }
    } else {
      alert("Por favor, selecione uma resposta antes de enviar.");
    }
  };

  return (
    <div className="quiz">
      <div className="card-question">
        <h2>{currentQuestion.question}</h2>
        <ul>
          {currentQuestion.answers.map((answer, index) => (
            <li
              key={index}
              onClick={() => handleAnswerClick(index, currentQuestion.correct)}
              className={`
                ${index === selectedAnswerIndex ? "active" : ""}
              `}
            >
              {answer}
            </li>
          ))}
        </ul>
        {showCorrectAnswer && (
          <div
            className={`feedback ${reasonForCorrectAnswer ? "reason-correct" : ""
              }`}
          >
            {reasonForCorrectAnswer}
          </div>
        )}
        {showCorrectAnswer && reasonForWrongAnswer && (
          <div
            className={`feedback ${reasonForWrongAnswer ? "reason-incorrect" : ""
              }`}
          >
            {reasonForWrongAnswer}
          </div>
        )}
        <div className="question-btn">{showSendButton && (
          <button className="questionNext" onClick={handleSendAnswer}>
            {nextButtonText}
          </button>
        )}
        {!showSendButton && (
          <button className="questionNext" onClick={handleNextQuestion}>
            {nextButtonText}
          </button>
        )}</div>
        
      </div>
    </div>
  );
};

export default Quiz;

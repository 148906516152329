// FAQDetails.js
import React from 'react';
import './FaqDetails.css';

const FAQDetails = ({ faqDetails }) => {
  if (!faqDetails) {
    return <p>FAQ não encontrada.</p>;
  }

  return (
    <div className='question-details'>
      <h2>{faqDetails.question}</h2>
      <div
        dangerouslySetInnerHTML={{ __html: faqDetails.answer }} // Renderiza o HTML recebido
      />
    </div>
  );
};

export default FAQDetails;

// src/components/FAQList.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IoDocumentTextOutline } from "react-icons/io5";
import './FAQList.css';

const FAQList = ({ moduleId, faqs }) => {
  const navigate = useNavigate();

  // Mapeamento inverso de moduleId para nomes legíveis
  const moduleMappingInverse = {
    1: 'onboarding',
    2: 'cadastro de produto',
    3: 'front',
    4: 'marketing',
    5: 'pagamentos',
    6: 'transporte',
    7: 'faturamento',
  };

  const filteredFaqs = faqs.filter(faq => faq.module === moduleId);
  console.log('Filtered FAQs:', filteredFaqs); 

  const handleQuestionClick = (faqId) => {
    const moduleName = moduleMappingInverse[moduleId];
    console.log("Navigating to:", `/faq/${moduleName}/${faqId}`);
    if (faqId) {
      navigate(`/faq/${moduleName}/${faqId}`);
    } else {
      console.error("FAQ ID is undefined. Cannot navigate.");
    }
  };

  return (
    <div className="faq-list">
      {filteredFaqs.length > 0 ? (
        <ul>
          {filteredFaqs.map(faq => (
            <li 
              key={faq.id} 
              className="faq-item" 
              onClick={() => handleQuestionClick(faq.id)} 
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            >
              <h3 className="faq-question">
                <IoDocumentTextOutline style={{ marginRight: '8px' }} /> 
                {faq.question}
                {console.log("FAQ ID:", faq.id)} 
              </h3>
              
            </li>
          ))}
          
        </ul>
      ) : (
        <p>Não há perguntas frequentes para este módulo.</p>
      )}
    </div>
  );
};

export default FAQList;

import React, { useState } from "react";
import "./Checklist.css";
import { FaRegCheckCircle } from "react-icons/fa";

const Checklist = ({ title, items, onChecklistComplete }) => {
  const [checkedItems, setCheckedItems] = useState(
    new Array(items.length).fill(false)
  );

  const handleItemClick = (index) => {
    const newCheckedItems = [...checkedItems];
    newCheckedItems[index] = !newCheckedItems[index];
    setCheckedItems(newCheckedItems);
  };

  const handleComplete = () => {
    if (checkedItems.every((item) => item)) {
      onChecklistComplete();
    } else {
      alert("Por favor, marque todos os itens antes de continuar.");
    }
  };

  return (
    
      <div className="checklist">
        <div className="card-checklist">
          <h2 className="checklist-title">{title}</h2>
          {items.map((item, index) => (
            <div
              key={index}
              onClick={() => handleItemClick(index)}
              className={`checklist-item ${checkedItems[index] ? "checked" : ""}`}
            >
              <span className="checklist-text">{item}</span>
              {checkedItems[index] && <FaRegCheckCircle className="checkicon" />}
            </div>
          ))}
          <div className="btn-checklist">
            <button className="checklistNext" onClick={handleComplete}>
              Concluir Checklist
            </button>
          </div>

        </div>
      </div>

  );
};

export default Checklist;

import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

const api = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use(config => {
  const token = localStorage.getItem('token'); 
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

export const checkAdminStatus = async (token) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/check-admin-status`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Erro ao verificar status do administrador:', error);
    throw error;
  }
};

export const registerUser = async (user) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/register`, user);
    return response.data;
  } catch (error) {
    console.error('Erro ao registrar usuário:', error);
    throw error;
  }
};

export const resetPassword = async (token, email, newPassword) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/api/reset-password`,
      { email, newPassword },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Erro ao redefinir senha:', error);
    throw error;
  }
};

export const deleteUser = async (token, email) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/api/users`, {
      headers: { Authorization: `Bearer ${token}` },
      data: { email }
    });
    return response.data;
  } catch (error) {
    console.error('Erro ao excluir usuário:', error);
    throw error;
  }
};

export const updateUserStatus = async (token, email, status) => {
  try {
    const response = await axios.patch(`${API_BASE_URL}/api/users/status`, { email, status }, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Erro ao atualizar status do usuário:', error);
    throw error;
  }
};

export const registerAccess = async (email) => {
  try {
    const token = localStorage.getItem('token'); 
    if (!token) {
      console.error('Token não encontrado');
      return;
    }

    const now = new Date().toISOString();

    await axios.post(`${API_BASE_URL}/api/acessos`, {
      email,
      data_acesso: now,
      data_saida: null 
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  } catch (error) {
    console.error('Erro ao registrar acesso:', error.message || error);
    if (error.response) {
      console.error('Status:', error.response.status);
      console.error('Dados:', error.response.data);
    } else if (error.request) {
      console.error('Nenhuma resposta recebida:', error.request);
    } else {
      console.error('Erro ao configurar a requisição:', error.message);
    }
  }
};

export const registerExit = async (email) => {
  try {
    const token = sessionStorage.getItem('token'); 
    if (!token) {
      console.error('Token não encontrado');
      return;
    }


    const response = await axios.put(`${API_BASE_URL}/api/acessos/saida`, {
      email
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

  } catch (error) {
    console.error('Erro ao atualizar a data de saída:', error.message || error);
    if (error.response) {
      console.error('Status da resposta:', error.response.status);
      console.error('Dados da resposta:', error.response.data);
    } else if (error.request) {
      console.error('Nenhuma resposta recebida:', error.request);
    } else {
      console.error('Erro ao configurar a requisição:', error.message);
    }
  }
};

export const fetchAccesses = async (email) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/acessos`, { params: { email } });
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar acessos:', error);
    throw error;
  }
};

export const createAccess = async (email, data_acesso, data_saida) => {
  try {
    await axios.post(`${API_BASE_URL}/api/acessos`, { email, data_acesso, data_saida });
  } catch (error) {
    console.error('Erro ao criar acesso:', error);
    throw error;
  }
};

export const loginAndRegisterAccess = async (email, password) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/login`, { email, password });
    const { token, isAdmin } = response.data;

    if (!token) {
      throw new Error('Token não recebido');
    }

    sessionStorage.setItem('token', token); 
    sessionStorage.setItem('is_admin', isAdmin ? 'true' : 'false'); 

    return { token, isAdmin, email };
  } catch (error) {
    console.error('Erro ao fazer login:', error.message || error);
    throw error;
  }
};

export const fetchUsers = async (search) => {
  const token = localStorage.getItem('token');
 
  
  try {
  
    const response = await axios.get(`${API_BASE_URL}/api/users`, { 
      params: { query: search }, 
      headers: { Authorization: `Bearer ${token}` } 
    });
   
    return response.data;
  } catch (error) {
    if (error.response) {
        console.error('Erro ao buscar usuários:', error.response.data);
        console.error('Código de status:', error.response.status);
    } else {
        console.error('Erro:', error);
    }
    throw error; 
  }
};

export const createFAQ = async (faqData, token) => {
  try {
    const response = await axios.post(
      
     `${API_BASE_URL}/api/faqs/create` , faqData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error('Erro ao criar FAQ', error);
    throw error;
  }
};

export const listFaq = async (token) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/faqs`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar FAQs:', error);
    throw error; 
  }
};


export const getFaqById = async (id, token) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/faqs/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar FAQ pelo ID:', error);
    throw error; 
  }
};

export const deleteFAQ = async (id, token) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/api/faqs/delete/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Erro ao deletar FAQ:', error);
    throw error;
  }
};



import React, { useState } from 'react';
import { registerUser } from '../../services/api'; // Ajuste o caminho conforme necessário
import './UserForm.css';

const UserForm = () => {
  const [idLoja, setIdLoja] = useState('');
  const [nomeLoja, setNomeLoja] = useState('');
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [status, setStatus] = useState(false);
  const [error, setError] = useState('');
  const [senhaErro, setSenhaErro] = useState('');

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (password.length < minLength) {
      return 'A senha deve ter pelo menos 8 caracteres.';
    }
    if (!hasUpperCase) {
      return 'A senha deve conter pelo menos uma letra maiúscula.';
    }
    if (!hasSpecialChar) {
      return 'A senha deve conter pelo menos um caractere especial.';
    }
    return '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    const senhaValidationError = validatePassword(senha);
    if (senhaValidationError) {
      setSenhaErro(senhaValidationError);
      return;
    }

    try {
      await registerUser({ 
        id_loja: idLoja,
        nome_loja: nomeLoja, 
        email, 
        senha, 
        status, 
        is_admin: false 
      });
      alert('Usuário cadastrado com sucesso');
      setIdLoja('');
      setNomeLoja('');
      setEmail('');
      setSenha('');
      setStatus(false);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          setError(error.response.data.message);
        } else {
          setError('Erro ao cadastrar usuário. Por favor, tente novamente.');
        }
      } else {
        setError('Erro ao conectar com o servidor. Verifique sua conexão.');
      }
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setSenha(newPassword);
    const senhaValidationError = validatePassword(newPassword);
    setSenhaErro(senhaValidationError);
  };

  return (
    <div className="user-form-container">
      <h1>Cadastro de Cliente</h1>
      <form onSubmit={handleSubmit}>
        {error && <div className="error-message">{error}</div>}

        <input
          type="text"
          value={idLoja}
          onChange={(e) => setIdLoja(e.target.value)}
          placeholder="ID da Loja"
          required
        />
        <input
          type="text"
          value={nomeLoja}
          onChange={(e) => setNomeLoja(e.target.value)}
          placeholder="Nome da Loja"
          required
        />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <input
          type="password"
          value={senha}
          onChange={handlePasswordChange}
          placeholder="Senha"
          required
        />
        {senhaErro && <div className="error-message">{senhaErro}</div>}

        <div className='checkbox-div'>
          <label>
            <p>Em Produção</p>
            <input
              type="checkbox"
              checked={status}
              onChange={() => setStatus(!status)}
            />
          </label>
        </div>

        <button type="submit" disabled={!!senhaErro}>Cadastrar</button> 
      </form>
    </div>
  );
};

export default UserForm;

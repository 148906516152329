import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { BsHouse } from 'react-icons/bs';
import { BsFillHouseFill } from 'react-icons/bs';
import './HomeIcon.css';

function HomeIcon() {
  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  return (
    <Link to="/" className="icon-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {hover ? <BsFillHouseFill className="icon filled" /> : <BsHouse className="icon outlined" />}
    </Link>
  );
}

export default HomeIcon;

import React, { useState, useEffect } from 'react';
import { fetchAccesses } from '../../services/api';
import './AcessLis.css';

const AccessDashboard = () => {
  const [accesses, setAccesses] = useState([]);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchAccesses(search);
        const sortedData = data.sort((a, b) => b.frequency - a.frequency); 
        setAccesses(sortedData);
        const pageSize = 25;
        setTotalPages(Math.ceil(sortedData.length / pageSize));
      } catch (error) {
        console.error('Erro ao buscar acessos:', error);
      }
    };

    fetchData();
  }, [search]);

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone 
    };
    return new Date(dateString).toLocaleString('pt-BR', options);
  };

  const pageSize = 25;
  const startIndex = (currentPage - 1) * pageSize;
  const paginatedAccesses = accesses.slice(startIndex, startIndex + pageSize);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className="access-dashboard-container">
      <input
        type="text"
        className="access-search-bar"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Buscar por email do usuário"
      />
      <table className="access-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Email</th>
            <th>Data de Acesso</th>
            <th>Data de Saída</th>
          </tr>
        </thead>
        <tbody>
          {paginatedAccesses.map(access => (
            <tr key={access.id}>
              <td>{access.id}</td>
              <td>{access.email}</td>
              <td>{formatDate(access.data_acesso)}</td>
              <td>{access.data_saida ? formatDate(access.data_saida) : '🟢'}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination-controls">
        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>⏮️</button>
        <span>Página {currentPage} de {totalPages}</span>
        <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>⏭️</button>
      </div>
    </div>
  );
};

export default AccessDashboard;

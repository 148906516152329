import React, { useMemo } from 'react';
import { useSpring, animated } from 'react-spring';
import './ProgressBar.css';

function ProgressBar({ currentVideoId, sections, moduleName }) {
  const totalVideos = useMemo(
    () => sections.reduce((acc, section) => acc + section.videos.length, 0),
    [sections]
  );
  const progress = useMemo(() => (currentVideoId / totalVideos) * 100, [currentVideoId, totalVideos]);

  const showCompletionMessage = progress >= 100;

  const barAnimation = useSpring({
    from: { width: '0%' },
    to: { width: `${progress}%` },
    config: { duration: 500 },
  });

  const messageAnimation = useSpring({
    from: { opacity: 0, transform: 'translateY(20px)' },
    to: { opacity: showCompletionMessage ? 1 : 0, transform: showCompletionMessage ? 'translateY(0)' : 'translateY(20px)' },
    delay: showCompletionMessage ? 300 : 0,
  });

  return (
    <div className="progress-bar-container">
      <div className="progress-text-container">
        {showCompletionMessage ? (
          <span className="completion-text">Parabéns! 🎉 Você concluiu o Módulo!</span>
        ) : (
          <span className="progress-text">Você já completou {Math.round(progress)}% desta trilha</span>
        )}
      </div>
      <animated.div className="progress-bar" style={barAnimation}></animated.div>
    </div>
  );
}

export default ProgressBar;
